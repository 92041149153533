import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Button, Form, Table, Spinner, Badge, Modal } from 'react-bootstrap';
import { refreshToken } from '../../components/Token/refreshToken';
import { FaArrowLeft, FaArrowRight, FaSearch, FaPause } from 'react-icons/fa';
import { formatCurrency } from '../../utils/formatCurrency';
import { translateStatus } from '../../utils/';
import { Anuncio, MeliAccount } from '../../components/types';

const AnuncioEstoque: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [products, setProducts] = useState<Anuncio[]>([]);
  const [meliAccounts, setMeliAccounts] = useState<MeliAccount[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<string | null>(null);
  const [limit, setLimit] = useState<number>(10);
  const [offset, setOffset] = useState<number>(0);
  const [totalProducts, setTotalProducts] = useState<number>(0);
  const [showModal, setShowModal] = useState<boolean>(false); // Estado da modal
  const [selectedProduct, setSelectedProduct] = useState<Anuncio | null>(null); // Produto selecionado para pausar
  const authToken = sessionStorage.getItem('authToken');

  // Fetch Meli Accounts on Mount
  useEffect(() => {
    fetchMeliAccounts();
  }, []);

  // Fetch Products whenever offset or limit changes
  useEffect(() => {
    if (selectedAccount) {
      fetchProducts();
    }
  }, [offset, limit]);

  const fetchMeliAccounts = async () => {
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_APP_API_URL}/meli-contas`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setMeliAccounts(response.data.data);
    } catch (error) {
      console.error('Erro ao buscar contas do Mercado Livre:', error);
      toast.error('Erro ao buscar contas do Mercado Livre');
    }
  };

  const fetchStockStatus = async (sku: string, accountId: string | null) => {
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_APP_API_URL}/check-stock`,
        {
          params: {
            sku: sku,
            meli_conta_id: accountId || 0,
          },
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error('Erro ao buscar status de estoque:', error);
      return { isok: false, quantidade: 0 };
    }
  };

  const fetchProducts = async () => {
    const token = sessionStorage.getItem('ml_access_token');
    const userId = sessionStorage.getItem('ml_user_id');

    if (!token || !userId) {
      handleIntegrationCheck();
      return;
    }

    setLoading(true);
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_APP_API_URL}/meli/products`,
        {
          headers: {
            AuthorizationMeli: `Bearer ${token}`,
          },
          params: {
            seller_id: userId,
            status: 'active',
            limit,
            offset,
          },
        }
      );
      const productIds = response.data.results;

      const productDetails = await Promise.all(
        productIds.map(async (id: string) => {
          const res = await axios.get(
            `https://api.mercadolibre.com/items/${id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          const product = res.data;

          // Verificar logistic_type diretamente no produto
          const logisticType = product.shipping?.logistic_type ?? 'normal';

          // Buscar o SELLER_SKU nos atributos
          const sellerSkuAttribute = product.attributes.find(
            (attr: any) => attr.id === 'SELLER_SKU'
          );
          const sellerSku = sellerSkuAttribute
            ? sellerSkuAttribute.value_name
            : 'N/A'; // Se não encontrar, mostrar 'N/A'

          // Verificar status de estoque
          const stockStatus = await fetchStockStatus(
            sellerSku,
            logisticType === 'full' ? selectedAccount : null
          );

          return {
            id: product.id,
            title: product.title,
            seller_sku: sellerSku, // Usar o valor encontrado ou 'N/A'
            available_quantity: product.available_quantity,
            price: product.price,
            status: product.status,
            permalink: product.permalink,
            thumbnail: product.thumbnail,
            logistic_type: logisticType === 'fulfillment' ? 'full' : 'normal',
            stock: stockStatus.quantidade,
            isok: stockStatus.isok,
          };
        })
      );

      setProducts(productDetails);
      setTotalProducts(response.data.paging.total);
      toast.success('Produtos buscados com sucesso!');
    } catch (error) {
      console.error('Erro ao buscar produtos:', error);
      toast.error('Erro ao buscar produtos.');
    } finally {
      setLoading(false);
    }
  };

  const handleIntegrationCheck = async () => {
    if (selectedAccount) {
      setLoading(true);
      try {
        await refreshToken(selectedAccount, setLoading);
        await fetchProducts();
      } catch (error) {
        console.error('Erro ao verificar integração:', error);
        toast.error('Erro ao verificar integração.');
      } finally {
        setLoading(false);
      }
    } else {
      toast.error('Selecione uma conta do Mercado Livre.');
    }
  };

  const handleNextPage = () => {
    if (offset + limit < totalProducts) {
      setOffset(offset + limit);
    }
  };

  const handlePreviousPage = () => {
    if (offset > 0) {
      setOffset(offset - limit);
    }
  };

  const handlePauseAnuncio = async () => {
    if (selectedProduct) {
      try {
        const mlAccessToken = sessionStorage.getItem('ml_access_token'); // Token de acesso do Mercado Livre

        if (!mlAccessToken) {
          toast.error('Token de autenticação não encontrado.');
          return;
        }

        await axios.put(
          `https://api.mercadolibre.com/items/${selectedProduct.id}`, // Endpoint da API do Mercado Livre para atualizar um item
          { status: 'paused' }, // Corpo da requisição com o novo status 'paused'
          {
            headers: {
              Authorization: `Bearer ${mlAccessToken}`, // Token de autenticação do Mercado Livre
              'Content-Type': 'application/json',
            },
          }
        );

        toast.success('Anúncio pausado com sucesso.');
        setShowModal(false); // Fechar a modal após confirmação
        await fetchProducts(); // Recarregar produtos após pausar
      } catch (error) {
        console.error('Erro ao pausar anúncio:', error);
        toast.error('Erro ao pausar anúncio.');
      }
    }
  };

  const renderStockColumn = (product: any) => {
    if (!product.isok) {
      return <Badge bg="danger">Produto não encontrado</Badge>;
    }
    return product.stock > 0 ? (
      <Badge bg="success">Disponível ({product.stock})</Badge> // Mostrar quantidade de estoque
    ) : (
      <Badge bg="warning">Sem Estoque</Badge>
    );
  };

  const renderPauseButton = (product: any) => {
    let buttonVariant = 'secondary';
    if (!product.isok) {
      buttonVariant = 'danger'; // Vermelho se o produto não for encontrado
    } else if (product.stock === 0) {
      buttonVariant = 'success'; // Verde se o estoque for 0
    }

    return (
      <Button
        variant={buttonVariant}
        onClick={() => {
          setSelectedProduct(product); // Definir o produto a ser pausado
          setShowModal(true); // Abrir a modal
        }}
        disabled={!product.isok || product.stock > 0} // Regras para habilitar/desabilitar o botão
      >
        <FaPause /> Pausar
      </Button>
    );
  };

  return (
    <div className="anuncio-estoque-container">
      <h1 className="mb-4 text-center">
        Anúncios Ativos vs Estoque IntegraMeli
      </h1>

      <div className="card mb-4 shadow-sm">
        <div className="card-body">
          {/* Linha com os elementos select e botão */}
          <div className="d-flex align-items-end mb-3">
            {/* Select de contas Mercado Livre */}
            <Form.Group className="me-2 flex-grow-1">
              <Form.Label>Conta do Mercado Livre</Form.Label>
              <Form.Control
                as="select"
                onChange={(e) => setSelectedAccount(e.target.value)}
                disabled={loading}
                value={selectedAccount || ''}
              >
                <option value="">Selecione uma conta</option>
                {meliAccounts.map((account) => (
                  <option key={account.id} value={account.id}>
                    {account.nome} {account.ativo ? '(Ativo)' : ''}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            {/* Select de limite de resultados por página */}
            <Form.Group className="me-2">
              <Form.Label>Quantidade por página</Form.Label>
              <Form.Control
                as="select"
                onChange={(e) => {
                  setLimit(Number(e.target.value));
                  setOffset(0); // Resetar para a primeira página
                }}
                disabled={loading}
                value={limit}
              >
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </Form.Control>
            </Form.Group>

            {/* Botão de buscar produtos */}
            <div className="align-self-end">
              <Button
                onClick={handleIntegrationCheck}
                className="w-auto"
                variant="primary"
                disabled={loading}
              >
                {loading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  <>
                    <FaSearch className="me-2" />
                    Buscar Anúncios
                  </>
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="card shadow-sm">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <span className="text-muted">
              Total de registros: <Badge bg="secondary">{totalProducts}</Badge>
            </span>
            <div>
              Página <strong>{Math.floor(offset / limit) + 1}</strong> de{' '}
              <strong>{Math.ceil(totalProducts / limit)}</strong>
            </div>
          </div>
          <div className="table-responsive">
            {loading ? (
              <div className="text-center">
                <Spinner animation="border" variant="primary" />
              </div>
            ) : (
              <Table
                striped
                className="table-striped"
                responsive="sm"
                bordered
                hover
              >
                <thead className="thead-dark table table-dark">
                  <tr>
                    <th>#</th>
                    <th>ID do Mercado Livre</th>
                    <th>Título</th>
                    <th>SKU</th>
                    <th>Quantidade Disponível</th>
                    <th>Preço</th>
                    <th>Status</th>
                    <th>Tipo de Logística</th>
                    <th>Estoque</th>
                    <th>Pausar Anúncio</th>
                    <th>Link</th>
                    <th>Imagem</th>
                  </tr>
                </thead>
                <tbody>
                  {products.length > 0 ? (
                    products.map((product, index) => (
                      <tr key={product.id}>
                        <td>{offset + index + 1}</td>
                        <td>{product.id}</td>
                        <td>{product.title}</td>
                        <td>{product.seller_sku}</td>
                        <td>{product.available_quantity}</td>
                        <td>{formatCurrency(product.price ?? 0)}</td>
                        <td>
                          <Badge
                            bg={
                              product.status === 'active'
                                ? 'success'
                                : 'warning'
                            }
                          >
                            {translateStatus(product.status ?? '')}
                          </Badge>
                        </td>
                        <td>
                          <Badge
                            bg={
                              product.logistic_type === 'full'
                                ? 'success'
                                : 'dark'
                            }
                          >
                            {product.logistic_type === 'full'
                              ? 'Full'
                              : 'Normal'}
                          </Badge>
                        </td>
                        <td>{renderStockColumn(product)}</td>
                        <td>{renderPauseButton(product)}</td>
                        <td>
                          <a
                            href={product.permalink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Ver anúncio
                          </a>
                        </td>
                        <td>
                          <img
                            src={product.thumbnail}
                            alt={product.title}
                            style={{ width: '50px', borderRadius: '5px' }}
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={12} className="text-center">
                        Nenhum produto encontrado.
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            )}
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-between mt-4">
        <Button
          onClick={handlePreviousPage}
          variant="outline-primary"
          disabled={loading || offset === 0}
        >
          <FaArrowLeft /> Página Anterior
        </Button>
        <Button
          onClick={handleNextPage}
          variant="outline-primary"
          disabled={loading || offset + limit >= totalProducts}
        >
          Próxima Página <FaArrowRight />
        </Button>
      </div>
      <hr />
      {/* Modal de confirmação para pausar o anúncio */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar Pausa</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Tem certeza que deseja pausar o anúncio "{selectedProduct?.title}"?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancelar
          </Button>
          <Button variant="warning" onClick={handlePauseAnuncio}>
            Confirmar Pausa
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AnuncioEstoque;
