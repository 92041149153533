import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaChartLine, FaWarehouse } from 'react-icons/fa';
import styled from 'styled-components';
import { PermissionWrapper } from '../../components/Permission';

// Estilos
const Container = styled.div`
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h1`
  font-weight: bold;
  color: #343a40;
  text-align: center;
  margin-bottom: 3rem;

  span {
    border-bottom: 4px solid #007bff;
    padding-bottom: 0.2rem;
  }
`;

const CardGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
`;

const Card = styled.div`
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 280px;
  text-align: center;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
`;

const IconWrapper = styled.div`
  margin-bottom: 1.5rem;
`;

const CardTitle = styled.h2`
  margin-top: 1rem;
  color: ${({ color }) => color || '#343a40'};
  font-size: 1.25rem;
`;

// Componente Principal
const Relatorios: React.FC = () => {
  const navigate = useNavigate();

  return (
    <PermissionWrapper screenId={'7'} permission="access">
      <Container>
        <Title>
          <span>Relatórios</span>
        </Title>
        <CardGrid>
          <Card onClick={() => navigate('/relatorios/relatoriosVenda')}>
            <IconWrapper>
              <FaChartLine size={80} color="#007bff" />
            </IconWrapper>
            <CardTitle color="#007bff">Relatórios de Vendas</CardTitle>
          </Card>
          <Card
            onClick={() =>
              navigate('/relatorios/relatoriosMovimentacaoEstoque')
            }
          >
            <IconWrapper>
              <FaWarehouse size={80} color="#28a745" />
            </IconWrapper>
            <CardTitle color="#28a745">Movimentação de Estoque</CardTitle>
          </Card>
        </CardGrid>
      </Container>
    </PermissionWrapper>
  );
};

export default Relatorios;
