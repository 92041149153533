import React, { useEffect, useState, useRef } from 'react';
import { Alert } from 'react-bootstrap';
import styled, { keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSync,
  faChartBar,
  faInfoCircle,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import Chart from 'chart.js/auto';
import api from '../../../services/api';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const slideIn = keyframes`
  from { transform: translateY(-20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`;

const rotate = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const Container = styled.div`
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  animation: ${fadeIn} 0.5s ease-out;
`;

const Title = styled.h2`
  color: #343a40;
  text-align: center;
  margin-bottom: 20px;
  animation: ${slideIn} 0.5s ease-out;
`;

const ChartContainer = styled.div`
  height: 400px;
  position: relative;
`;

const LoadingSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 2rem;
  color: #dc3545;
  animation: ${rotate} 1s linear infinite;
`;

const RefreshButton = styled.button`
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 20px;

  &:hover {
    background-color: #c82333;
  }
`;

const StyledAlert = styled(Alert)`
  animation: ${slideIn} 0.5s ease-out;
`;

const InfoIcon = styled(FontAwesomeIcon)`
  margin-left: 10px;
  cursor: pointer;
  color: #17a2b8;
`;

const TooltipContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const Tooltip = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #17a2b8;
  color: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  width: 200px;
  animation: ${fadeIn} 0.3s ease-out;
`;

const WarningBanner = styled.div`
  background-color: #ffeeba;
  color: #856404;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  animation: ${slideIn} 0.5s ease-out;

  svg {
    margin-right: 10px;
  }
`;

const EnhancedBiEstoqueMin: React.FC = () => {
  const [data, setData] = useState<
    Array<{
      descricao: string;
      sku: string;
      quantidade_minima: number;
      nome: string;
    }>
  >([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const chartRef = useRef<HTMLCanvasElement>(null);
  const chartInstanceRef = useRef<Chart | null>(null);

  const fetchData = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await api.get(
        `${import.meta.env.VITE_APP_API_URL}/bi_estoqueMin`
      );
      setData(response.data.data);
    } catch (error) {
      console.error('Erro ao buscar dados de estoque mínimo:', error);
      setError('Erro ao buscar dados de estoque mínimo.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (!loading && !error && chartRef.current) {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }

      const ctx = chartRef.current.getContext('2d');
      if (ctx) {
        chartInstanceRef.current = new Chart(ctx, {
          type: 'bar',
          data: {
            labels: data.map((item) => item.descricao),
            datasets: [
              {
                label: 'Quantidade Mínima',
                data: data.map((item) => item.quantidade_minima),
                backgroundColor: 'rgba(255, 99, 132, 0.6)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
              },
            ],
          },
          options: {
            indexAxis: 'y',
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              x: {
                beginAtZero: true,
              },
            },
            plugins: {
              title: {
                display: true,
                text: '10 Produtos com Menor Estoque',
                font: {
                  size: 18,
                  weight: 'bold',
                },
              },
              tooltip: {
                callbacks: {
                  label: function (context) {
                    return `Quantidade Mínima: ${context.raw}`;
                  },
                },
              },
              legend: {
                display: false,
              },
            },
            animation: {
              duration: 1000,
              easing: 'easeOutQuart',
            },
          },
        });
      }
    }
  }, [loading, error, data]);

  useEffect(() => {
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, []);

  return (
    <Container>
      <Title>
        <FontAwesomeIcon icon={faChartBar} /> Análise de Estoque Mínimo
      </Title>
      <WarningBanner>
        <FontAwesomeIcon icon={faExclamationTriangle} />
        Atenção: Produtos com estoque baixo podem precisar de reposição!
      </WarningBanner>
      {loading ? (
        <LoadingSpinner>
          <FontAwesomeIcon icon={faSync} />
        </LoadingSpinner>
      ) : error ? (
        <StyledAlert variant="danger">{error}</StyledAlert>
      ) : (
        <ChartContainer>
          <canvas ref={chartRef}></canvas>
          <TooltipContainer>
            <InfoIcon
              icon={faInfoCircle}
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
            />
            {showTooltip && (
              <Tooltip>
                Este gráfico mostra os 10 produtos com menor quantidade em
                estoque.
              </Tooltip>
            )}
          </TooltipContainer>
        </ChartContainer>
      )}
      <RefreshButton onClick={fetchData}>
        <FontAwesomeIcon icon={faSync} /> Atualizar Dados
      </RefreshButton>
    </Container>
  );
};

export default EnhancedBiEstoqueMin;
