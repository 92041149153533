import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Compra } from '../../components/types';
import { toast } from 'react-toastify';
import {
  Button,
  Spinner,
  Card,
  Row,
  Col,
  Badge,
  Container,
} from 'react-bootstrap';
import {
  FaClipboardList,
  FaRegCalendarAlt,
  FaRegMoneyBillAlt,
  FaFileInvoiceDollar,
} from 'react-icons/fa';
import moment from 'moment';
import api from '../../services/api';

const CompraDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [compra, setCompra] = useState<Compra | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCompra = async () => {
      try {
        const response = await api.get(
          `${import.meta.env.VITE_APP_API_URL}/compras/${id}`
        );
        setCompra(response.data.data);
      } catch (error) {
        toast.error('Erro ao buscar detalhes da compra.');
      } finally {
        setLoading(false);
      }
    };

    fetchCompra();
  }, [id]);

  if (loading) {
    return (
      <div className="text-center">
        <Spinner animation="border" role="status">
          <span className="sr-only">...</span>
        </Spinner>
      </div>
    );
  }

  if (!compra) {
    return <p>Compra não encontrada.</p>;
  }

  return (
    <Container className="mt-4">
      <Card>
        <Card.Header className="bg-primary text-white d-flex justify-content-between align-items-center">
          <h5 className="mb-0">
            <FaClipboardList className="mr-2" /> Detalhes da Compra
          </h5>
          <Badge pill bg={compra.status === 'pendente' ? 'warning' : 'success'}>
            {compra.status
              ? compra.status.charAt(0).toUpperCase() + compra.status.slice(1)
              : 'N/A'}
          </Badge>
        </Card.Header>
        <Card.Body>
          {compra.itens.map((item, index) => (
            <Card key={index} className="mb-3">
              <Card.Body>
                <Row>
                  <Col md={6}>
                    <Card.Title className="text-primary">
                      <FaFileInvoiceDollar className="mr-2" /> Produto
                    </Card.Title>
                    <Card.Text>{item.produto.descricao}</Card.Text>
                  </Col>
                  <Col md={3}>
                    <Card.Title className="text-primary">
                      <FaRegMoneyBillAlt className="mr-2" /> Valor Unitário
                    </Card.Title>
                    <Card.Text>{item.valor_unitario}</Card.Text>
                  </Col>
                  <Col md={3}>
                    <Card.Title className="text-primary">
                      <FaRegMoneyBillAlt className="mr-2" /> Quantidade
                    </Card.Title>
                    <Card.Text>{item.quantidade}</Card.Text>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Card.Title className="text-primary">
                      <FaRegMoneyBillAlt className="mr-2" /> Valor Total
                    </Card.Title>
                    <Card.Text>{item.valor_total}</Card.Text>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          ))}
          <hr />
          <Row>
            <Col md={6}>
              <Card.Title className="text-primary">
                <FaRegCalendarAlt className="mr-2" /> Nota Fiscal
              </Card.Title>
              <Card.Text>{compra.nota_fiscal || 'N/A'}</Card.Text>
            </Col>
            <Col md={6}>
              <Card.Title className="text-primary">
                <FaRegCalendarAlt className="mr-2" /> Data da Compra
              </Card.Title>
              <Card.Text>
                {compra.data_compra
                  ? moment(compra.data_compra).format('DD/MM/YYYY')
                  : 'N/A'}
              </Card.Text>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md={4}>
              <Card.Title className="text-primary">Fornecedor</Card.Title>
              <Card.Text>
                <strong>CNPJ:</strong> {compra.cnpj_fornecedor || 'N/A'}
                <br />
                <strong>Nome:</strong> {compra.nome_fornecedor || 'N/A'}
                <br />
                <strong>Endereço:</strong> {compra.endereco_fornecedor || 'N/A'}
                <br />
                <strong>Cidade:</strong> {compra.cidade_fornecedor || 'N/A'}
                <br />
                <strong>Estado:</strong> {compra.estado_fornecedor || 'N/A'}
                <br />
                <strong>CEP:</strong> {compra.cep_fornecedor || 'N/A'}
              </Card.Text>
            </Col>
            <Col md={8}>
              <Card.Title className="text-primary">
                Informações Adicionais
              </Card.Title>
              <Card.Text>
                <strong>Prazo de Entrega:</strong>{' '}
                {compra.prazo_entrega
                  ? moment(compra.prazo_entrega).format('DD/MM/YYYY')
                  : 'N/A'}
                <br />
                <strong>Forma de Pagamento:</strong>{' '}
                {compra.forma_pagamento || 'N/A'}
                <br />
                <strong>Observações:</strong> {compra.observacoes || 'N/A'}
              </Card.Text>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col md={6}>
              <Card.Text>
                <strong>Data de Criação:</strong>{' '}
                {moment(compra.created_at).format('DD/MM/YYYY HH:mm:ss')}
              </Card.Text>
            </Col>
            <Col md={6}>
              <Card.Text>
                <strong>Última Atualização:</strong>{' '}
                {moment(compra.updated_at).format('DD/MM/YYYY HH:mm:ss')}
              </Card.Text>
            </Col>
          </Row>
          <Button variant="primary" onClick={() => navigate('/compras')}>
            Voltar
          </Button>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default CompraDetailPage;
