import React from 'react';
import AppNavbar from '../Modulo/components/AppNavbar';
import News from '../../components/News/News';

const Noticias: React.FC = () => {
  return (
    <div>
      <AppNavbar />
      <News />
    </div>
  );
};

export default Noticias;
