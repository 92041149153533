import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Modal, Button, Form, Spinner, Alert } from 'react-bootstrap';
import Tokens from '../../components/Token/Tokens';
import { toast } from 'react-toastify';
import { PermissionWrapper } from '../../components/Permission';

const Integracao: React.FC = () => {
  const [authCode, setAuthCode] = useState<string | null>(null);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [meliAccounts, setMeliAccounts] = useState<any[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<string | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [tokensUpdated, setTokensUpdated] = useState<boolean>(false);
  const [userInfo] = useState<any>(null);
  const [showUserModal, setShowUserModal] = useState<boolean>(false);

  useEffect(() => {
    const storedToken = sessionStorage.getItem('ml_access_token');
    const tokenExpiry = sessionStorage.getItem('ml_token_expiry');

    if (storedToken && tokenExpiry && new Date(tokenExpiry) > new Date()) {
      setAccessToken(storedToken);
    } else {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      if (code) {
        setAuthCode(code);
        fetchMeliAccounts(); // Mostrar o modal para selecionar a conta
      }
    }
  }, []);

  const redirectToMLAuth = () => {
    const clientId = import.meta.env.VITE_APP_CLIENT_ID;
    const redirectUri = `https://app.integrameli.com.br/integracao`;
    const authUrl = `https://auth.mercadolivre.com.br/authorization?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}`;
    window.location.href = authUrl;
  };

  const fetchAccessToken = async (
    authCode: string,
    selectedAccount: string
  ) => {
    const clientId = import.meta.env.VITE_APP_CLIENT_ID;
    const clientSecret = import.meta.env.VITE_APP_CLIENT_SECRET;
    const redirectUri = `https://app.integrameli.com.br/integracao`;

    try {
      setLoading(true);
      const response = await axios.post(
        'https://api.mercadolibre.com/oauth/token',
        {
          grant_type: 'authorization_code',
          client_id: clientId,
          client_secret: clientSecret,
          code: authCode,
          redirect_uri: redirectUri,
        },
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );

      const tokenData = response.data;
      setAccessToken(tokenData.access_token);
      sessionStorage.setItem('ml_access_token', tokenData.access_token);
      sessionStorage.setItem(
        'ml_token_expiry',
        new Date(Date.now() + tokenData.expires_in * 1000).toString()
      );

      // Salvar token em arquivo JSON
      saveTokenToFile(tokenData);

      saveTokenToAPI({
        access_token: tokenData.access_token,
        refresh_token: tokenData.refresh_token,
        user_id: selectedAccount,
        tipo: 'access_token',
        user_mercado_livre_id: tokenData.user_id,
      });
    } catch (error) {
      console.error('Erro ao obter o token de acesso:', error);
      toast.error('Erro ao obter o token de acesso');
      setError('Erro ao obter o token de acesso');
    } finally {
      setLoading(false);
    }
  };

  const fetchMeliAccounts = async () => {
    try {
      const response = await axios.get(
        import.meta.env.VITE_APP_API_URL + '/meli-contas',
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('authToken')}`,
          },
        }
      );
      setMeliAccounts(response.data.data);
      setShowModal(true);
    } catch (error) {
      console.error('Erro ao buscar contas do Mercado Livre:', error);
      toast.error('Erro ao buscar contas do Mercado Livre');
      setError('Erro ao buscar contas do Mercado Livre');
    }
  };

  const handleAccountSelect = (event: React.ChangeEvent<any>) => {
    setSelectedAccount(event.target.value);
  };

  const handleIntegration = () => {
    if (selectedAccount && authCode) {
      // Verificar se já existe um token na API
      checkExistingToken(selectedAccount)
        .then((exists) => {
          if (exists) {
            toast.error('Já existe um token para essa conta hoje.');
            setError('Já existe um token para essa conta hoje.');
          } else {
            fetchAccessToken(authCode, selectedAccount);
            setShowModal(false);
          }
        })
        .catch((error) => {
          console.error('Erro ao verificar existência do token:', error);
          toast.error('Erro ao verificar existência do token.');
          setError('Erro ao verificar existência do token.');
        });
    } else {
      toast.error('Selecione uma conta do Mercado Livre para integrar');
      setError('Selecione uma conta do Mercado Livre para integrar');
    }
  };

  const saveTokenToAPI = async (data: any) => {
    try {
      const response = await axios.post(
        import.meta.env.VITE_APP_API_URL + `/meli-tokens`,
        {
          id: data.user_id, // ID da conta selecionada
          access_token: data.access_token,
          refresh_token: data.refresh_token,
          token_type: 'bearer',
          tipo: data.tipo,
          expires_in: 21600, // 6 horas
          user_mercado_livre_id: data.user_mercado_livre_id,
          meliConta_id: data.user_id, // ID da conta selecionada
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('authToken')}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 201) {
        toast.success('Token salvo com sucesso!');
        setTokensUpdated(true); // Atualiza os tokens após salvar na API
      } else {
        console.error('Erro ao salvar o token:', response.status);
        toast.error('Erro ao salvar o token');
        setError('Erro ao salvar o token');
      }
    } catch (error) {
      console.error('Erro ao salvar o token:', error);
      toast.error('Erro ao salvar o token');
      setError('Erro ao salvar o token');
    }
  };

  const checkExistingToken = async (accountId: string): Promise<boolean> => {
    try {
      const response = await axios.get(
        import.meta.env.VITE_APP_API_URL +
          `/meli-tokens/check-today/${accountId}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('authToken')}`,
          },
        }
      );
      return response.data.exists;
    } catch (error) {
      console.error('Erro ao verificar existência do token:', error);
      throw error;
    }
  };

  const saveTokenToFile = (tokenData: any) => {
    const tokenJson = JSON.stringify(tokenData, null, 2);
    const blob = new Blob([tokenJson], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `token_${tokenData.user_id}.json`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
    toast.success('Token salvo e baixado como arquivo JSON');
  };

  const handleCancel = () => {
    setShowModal(false);
    setTimeout(() => {
      setShowModal(true);
    }, 100); // Pequeno delay para reabrir o modal
  };

  return (
    <PermissionWrapper screenId={'10'} permission="access">
      <div className="container-fluid">
        <div className="text-center" style={{ backgroundColor: 'white' }}>
          <h1>Integração</h1>
          {loading ? (
            <Spinner animation="border" />
          ) : error ? (
            <Alert variant="danger">{error}</Alert>
          ) : authCode ? (
            <p>{accessToken}</p>
          ) : (
            <Button onClick={redirectToMLAuth}>
              Integrar com Mercado Livre
            </Button>
          )}
        </div>
        <Modal show={showModal} onHide={handleCancel}>
          <Modal.Header closeButton>
            <Modal.Title>Selecione a Conta do Mercado Livre</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Conta do Mercado Livre</Form.Label>
              <Form.Control as="select" onChange={handleAccountSelect}>
                <option value="">Selecione uma conta</option>
                {meliAccounts.map((account) => (
                  <option key={account.id} value={account.id}>
                    {account.nome}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" href="/">
              Cancelar
            </Button>
            <Button variant="primary" onClick={handleIntegration}>
              Confirmar
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showUserModal} onHide={() => setShowUserModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Informações do Usuário</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {userInfo ? (
              <div>
                <p>
                  <strong>ID:</strong> {userInfo.id}
                </p>
                <p>
                  <strong>Nickname:</strong> {userInfo.nickname}
                </p>
                <p>
                  <strong>Email:</strong> {userInfo.email}
                </p>
                <p>
                  <strong>First Name:</strong> {userInfo.first_name}
                </p>
                <p>
                  <strong>Last Name:</strong> {userInfo.last_name}
                </p>
              </div>
            ) : (
              <p>Não foi possível obter as informações do usuário.</p>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowUserModal(false)}>
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>
        <Tokens updated={tokensUpdated} />
      </div>
    </PermissionWrapper>
  );
};

export default Integracao;
