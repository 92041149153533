import React, { useState, useEffect } from 'react';
import {
  Table,
  Button,
  Spinner,
  InputGroup,
  FormControl,
  FormSelect,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import ExcelJS from 'exceljs';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import api from '../../services/api';

const RelatorioMovimentacaoEstoque: React.FC = () => {
  const [movimentos, setMovimentos] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [perPage, setPerPage] = useState<number>(15);
  const [total, setTotal] = useState<number>(0);
  const [to, setTo] = useState<number>(0);
  const [search, setSearch] = useState<string>('');

  const fetchMovimentos = async (page: number, searchQuery: string = '') => {
    setLoading(true);

    try {
      let url = `${
        import.meta.env.VITE_APP_API_URL
      }/relmovimentoEstoque?page=${page}&per_page=${perPage}`;
      if (searchQuery) {
        url += `&search=${searchQuery}`;
      }

      const response = await api.get(url);

      setMovimentos(response.data.data);
      setCurrentPage(response.data.meta.current_page);
      setTotalPages(response.data.meta.last_page);
      setPerPage(response.data.meta.per_page);
      setTotal(response.data.meta.total);
      setTo(response.data.meta.to);
    } catch (error) {
      console.error('Erro ao buscar movimentações:', error);
      alert('Erro ao buscar movimentações.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMovimentos(currentPage);
  }, [currentPage, perPage]);

  const handlePageChange = (newPage: number) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleSearchKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      fetchMovimentos(1, search);
    }
  };

  const handlePerPageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const exportToExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Movimentações de Estoque');

    worksheet.columns = [
      { header: 'Descrição', key: 'descricao', width: 30 },
      { header: 'SKU', key: 'sku', width: 20 },
      { header: 'Quantidade', key: 'quantidade', width: 15 },
      { header: 'Tipo', key: 'tipo', width: 20 },
      { header: 'Usuário', key: 'usuario', width: 30 },
      { header: 'Data de Criação', key: 'created_at', width: 25 },
      { header: 'Data de Atualização', key: 'updated_at', width: 25 },
    ];

    movimentos.forEach((movimento) => {
      worksheet.addRow({
        ...movimento,
        created_at: new Date(movimento.created_at).toLocaleString(),
        updated_at: new Date(movimento.updated_at).toLocaleString(),
      });
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'relatorio_movimentacao_estoque.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const exportToPDF = () => {
    const pdf = new jsPDF('landscape');
    const title = 'Relatório de Movimentação de Estoque';
    const tableColumn = [
      'Descrição',
      'SKU',
      'Quantidade',
      'Tipo',
      'Usuário',
      'Data de Criação',
      'Data de Atualização',
    ];
    const tableRows: any[] = [];

    movimentos.forEach((movimento) => {
      const movimentoData = [
        movimento.descricao,
        movimento.sku,
        movimento.quantidade,
        movimento.tipo,
        movimento.usuario,
        new Date(movimento.created_at).toLocaleString(),
        new Date(movimento.updated_at).toLocaleString(),
      ];
      tableRows.push(movimentoData);
    });

    pdf.setFontSize(18);
    pdf.text(title, 13, 8);
    pdf.setFontSize(12);
    pdf.text('Data: ' + new Date().toLocaleDateString(), 240, 8);

    autoTable(pdf, {
      head: [tableColumn],
      body: tableRows,
      startY: 20,
      theme: 'grid',
      styles: { fontSize: 8, cellPadding: 2 },
      headStyles: { fillColor: [0, 0, 0], textColor: [255, 255, 255] },
    });

    pdf.save('relatorio_movimentacao_estoque.pdf');
  };

  return (
    <div className="container-fluid">
      <h1 className="text-center mb-4">Relatório de Movimentação de Estoque</h1>

      <div className="d-flex flex-wrap justify-content-between align-items-center mb-3">
        <div className="d-flex flex-grow-1 align-items-center mr-2 mb-2">
          <InputGroup className="flex-grow-1">
            <FormControl
              placeholder="Pesquisar por Descrição, SKU..."
              aria-label="Pesquisar"
              value={search}
              onChange={handleSearchChange}
              onKeyDown={handleSearchKeyPress}
              className="w-100"
            />
          </InputGroup>
        </div>
        <div className="d-flex align-items-center">
          <Button variant="info" onClick={exportToExcel} className="mr-2 mb-2">
            <FontAwesomeIcon icon={faFileExcel} /> Exportar para Excel
          </Button>
          <Button variant="warning" onClick={exportToPDF} className="mr-2 mb-2">
            <FontAwesomeIcon icon={faFilePdf} /> Exportar para PDF
          </Button>
          <FormSelect
            value={perPage}
            onChange={handlePerPageChange}
            className="mb-2"
            style={{ width: '100px' }}
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={15}>15</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </FormSelect>
        </div>
      </div>

      {loading ? (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="sr-only">Carregando...</span>
          </Spinner>
        </div>
      ) : (
        <>
          <div className="table-responsive">
            <Table hover className="table-striped">
              <thead className="thead-dark table table-dark">
                <tr>
                  <th>Descrição</th>
                  <th>SKU</th>
                  <th>Quantidade</th>
                  <th>Tipo</th>
                  <th>Usuário</th>
                  <th>Data de Criação</th>
                  <th>Data de Atualização</th>
                </tr>
              </thead>
              <tbody>
                {movimentos.map((movimento) => (
                  <tr key={movimento.id}>
                    <td>{movimento.descricao}</td>
                    <td>{movimento.sku}</td>
                    <td>{movimento.quantidade}</td>
                    <td>{movimento.tipo}</td>
                    <td>{movimento.usuario}</td>
                    <td>{new Date(movimento.created_at).toLocaleString()}</td>
                    <td>{new Date(movimento.updated_at).toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <br />
          <p className="text-muted">
            Mostrando {to} de {total} movimentações
          </p>
          <nav>
            <ul className="pagination justify-content-center">
              <li
                className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}
              >
                <button
                  className="page-link"
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  Anterior
                </button>
              </li>
              {[...Array(totalPages)].map((_, index) => (
                <li
                  key={index}
                  className={`page-item ${
                    currentPage === index + 1 ? 'active' : ''
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </button>
                </li>
              ))}
              <li
                className={`page-item ${
                  currentPage === totalPages ? 'disabled' : ''
                }`}
              >
                <button
                  className="page-link"
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  Próxima
                </button>
              </li>
            </ul>
          </nav>
        </>
      )}
    </div>
  );
};

export default RelatorioMovimentacaoEstoque;
