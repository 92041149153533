import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';

interface ChangePasswordModalProps {
  show: boolean;
  onHide: () => void;
}

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({
  show,
  onHide,
}) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState(''); // Adicionado campo de confirmação
  const [loading, setLoading] = useState(false);
  const userEmail = sessionStorage.getItem('userEmail'); // Obtém o email do sessionStorage
  const userId = sessionStorage.getItem('userId');
  const name = sessionStorage.getItem('userName');
  // Obtém o ID do sessionStorage
  const authToken = sessionStorage.getItem('authToken');

  const handlePasswordChange = async () => {
    if (!newPassword || !confirmPassword) {
      toast.error('Por favor, preencha todos os campos.');
      return;
    }

    if (newPassword !== confirmPassword) {
      toast.error('As senhas não coincidem. Por favor, tente novamente.');
      return;
    }

    setLoading(true);
    try {
      // Faz a requisição PUT para o endpoint de atualização de senha
      await axios.put(
        `${import.meta.env.VITE_APP_API_URL}/users/${userId}`,
        {
          name,
          role: 'busca',
          email: userEmail,
          password: newPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      toast.success('Senha atualizada com sucesso!');
      setNewPassword('');
      setConfirmPassword(''); // Limpa o campo de confirmação
      onHide(); // Fecha o modal após o sucesso
    } catch (error) {
      toast.error('Erro ao atualizar a senha. Por favor, tente novamente.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Trocar Senha</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="userEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" value={userEmail || ''} readOnly />
          </Form.Group>
          <Form.Group controlId="newPassword" className="mt-3">
            <Form.Label>Nova Senha</Form.Label>
            <Form.Control
              type="password"
              placeholder="Digite sua nova senha"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="confirmPassword" className="mt-3">
            <Form.Label>Confirme a Nova Senha</Form.Label>
            <Form.Control
              type="password"
              placeholder="Confirme sua nova senha"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancelar
        </Button>
        <Button
          variant="primary"
          onClick={handlePasswordChange}
          disabled={loading}
        >
          {loading ? 'Salvando...' : 'Salvar'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangePasswordModal;
