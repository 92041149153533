import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col, Card, Container } from 'react-bootstrap';
import { Sale } from './SalesTable';

interface SalesFormProps {
  sale?: Sale;
  onSave: (sale: Sale) => void;
}

const SalesForm: React.FC<SalesFormProps> = ({ sale, onSave }) => {
  const [formData, setFormData] = useState<Sale>({
    id: sale?.id || '',
    id_venda: sale?.id_venda || '',
    date_created: sale?.date_created || '',
    buyer_nickname: sale?.buyer_nickname || '',
    item_title: sale?.item_title || '',
    seller_sku: sale?.seller_sku || '',
    quantity: sale?.quantity || 1,
    unit_price: sale?.unit_price || 0,
    full_unit_price: sale?.full_unit_price || 0,
    total_paid_amount: sale?.total_paid_amount || 0,
    sale_fee: sale?.sale_fee || 0,
    shipping_cost: sale?.shipping_cost || 0,
    listing_type: sale?.listing_type || '',
    delivery_status: sale?.delivery_status || '',
    total: sale?.total || 0,
    receiver_name: sale?.receiver_name || '',
    address_line: sale?.address_line || '',
    city: sale?.city || '',
    state: sale?.state || '',
    zip_code: sale?.zip_code || '',
    receiver_phone: sale?.receiver_phone || '',
    meli_conta_id: sale?.meli_conta_id || '',
    status: sale?.status || 'pendente',
  });

  useEffect(() => {
    setFormData({
      id: sale?.id || '',
      id_venda: sale?.id_venda || '',
      date_created: sale?.date_created || '',
      buyer_nickname: sale?.buyer_nickname || '',
      item_title: sale?.item_title || '',
      seller_sku: sale?.seller_sku || '',
      quantity: sale?.quantity || 1,
      unit_price: sale?.unit_price || 0,
      full_unit_price: sale?.full_unit_price || 0,
      total_paid_amount: sale?.total_paid_amount || 0,
      sale_fee: sale?.sale_fee || 0,
      shipping_cost: sale?.shipping_cost || 0,
      listing_type: sale?.listing_type || '',
      delivery_status: sale?.delivery_status || '',
      total: sale?.total || 0,
      receiver_name: sale?.receiver_name || '',
      address_line: sale?.address_line || '',
      city: sale?.city || '',
      state: sale?.state || '',
      zip_code: sale?.zip_code || '',
      receiver_phone: sale?.receiver_phone || '',
      meli_conta_id: sale?.meli_conta_id || '',
      status: sale?.status || 'pendente',
    });
  }, [sale]);

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSave(formData);
  };

  return (
    <Container className="my-4">
      <Card>
        <Card.Header as="h5">
          {sale ? 'Editar Venda' : 'Adicionar Nova Venda'}
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Row>
              {/* Informações Básicas */}
              <Col md={6}>
                <Form.Group controlId="formIdVenda" className="mb-3">
                  <Form.Label>ID da Venda</Form.Label>
                  <Form.Control
                    type="text"
                    name="id_venda"
                    value={formData.id_venda}
                    onChange={handleChange}
                    readOnly={!!sale} // Apenas leitura se estiver editando
                    placeholder="Insira o ID da Venda"
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formMeliContaId" className="mb-3">
                  <Form.Label>ID da Conta Mercado Livre</Form.Label>
                  <Form.Control
                    type="text"
                    name="meli_conta_id"
                    value={formData.meli_conta_id}
                    onChange={handleChange}
                    readOnly={!!sale} // Apenas leitura se estiver editando
                    placeholder="Insira o ID da Conta"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group controlId="formDateCreated" className="mb-3">
                  <Form.Label>Data de Criação</Form.Label>
                  <Form.Control
                    type="datetime-local"
                    name="date_created"
                    value={formData.date_created}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formBuyerNickname" className="mb-3">
                  <Form.Label>Apelido do Comprador</Form.Label>
                  <Form.Control
                    type="text"
                    name="buyer_nickname"
                    value={formData.buyer_nickname}
                    onChange={handleChange}
                    placeholder="Insira o Apelido do Comprador"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group controlId="formItemTitle" className="mb-3">
                  <Form.Label>Título do Item</Form.Label>
                  <Form.Control
                    type="text"
                    name="item_title"
                    value={formData.item_title}
                    onChange={handleChange}
                    placeholder="Insira o Título do Item"
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formSellerSku" className="mb-3">
                  <Form.Label>SKU do Vendedor</Form.Label>
                  <Form.Control
                    type="text"
                    name="seller_sku"
                    value={formData.seller_sku}
                    onChange={handleChange}
                    placeholder="Insira o SKU do Vendedor"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={4}>
                <Form.Group controlId="formQuantity" className="mb-3">
                  <Form.Label>Quantidade</Form.Label>
                  <Form.Control
                    type="number"
                    name="quantity"
                    value={formData.quantity}
                    onChange={handleChange}
                    min="1"
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="formUnitPrice" className="mb-3">
                  <Form.Label>Preço Unitário</Form.Label>
                  <Form.Control
                    type="number"
                    name="unit_price"
                    value={formData.unit_price}
                    onChange={handleChange}
                    min="0"
                    step="0.01"
                    placeholder="0.00"
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="formFullUnitPrice" className="mb-3">
                  <Form.Label>Preço Total do Item</Form.Label>
                  <Form.Control
                    type="number"
                    name="full_unit_price"
                    value={formData.full_unit_price}
                    onChange={handleChange}
                    min="0"
                    step="0.01"
                    placeholder="0.00"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={4}>
                <Form.Group controlId="formTotalPaidAmount" className="mb-3">
                  <Form.Label>Valor Total Pago</Form.Label>
                  <Form.Control
                    type="number"
                    name="total_paid_amount"
                    value={formData.total_paid_amount}
                    onChange={handleChange}
                    min="0"
                    step="0.01"
                    placeholder="0.00"
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="formSaleFee" className="mb-3">
                  <Form.Label>Tarifa de Venda</Form.Label>
                  <Form.Control
                    type="number"
                    name="sale_fee"
                    value={formData.sale_fee}
                    onChange={handleChange}
                    min="0"
                    step="0.01"
                    placeholder="0.00"
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="formShippingCost" className="mb-3">
                  <Form.Label>Tarifa de Envio</Form.Label>
                  <Form.Control
                    type="number"
                    name="shipping_cost"
                    value={formData.shipping_cost}
                    onChange={handleChange}
                    min="0"
                    step="0.01"
                    placeholder="0.00"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group controlId="formListingType" className="mb-3">
                  <Form.Label>Tipo de Anúncio</Form.Label>
                  <Form.Control
                    type="text"
                    name="listing_type"
                    value={formData.listing_type}
                    onChange={handleChange}
                    placeholder="Insira o Tipo de Anúncio"
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formDeliveryStatus" className="mb-3">
                  <Form.Label>Status de Entrega</Form.Label>
                  <Form.Control
                    type="text"
                    name="delivery_status"
                    value={formData.delivery_status}
                    onChange={handleChange}
                    placeholder="Insira o Status de Entrega"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group controlId="formStatus" className="mb-3">
                  <Form.Label>Status da Venda</Form.Label>
                  <Form.Control
                    as="select"
                    name="status"
                    value={formData.status}
                    onChange={handleChange}
                  >
                    <option value="pendente">Pendente</option>
                    <option value="processando">Processando</option>
                    <option value="concluído">Concluído</option>
                    <option value="cancelado">Cancelado</option>
                    {/* Adicione outros status conforme necessário */}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formTotal" className="mb-3">
                  <Form.Label>Total</Form.Label>
                  <Form.Control
                    type="number"
                    name="total"
                    value={formData.total}
                    onChange={handleChange}
                    min="0"
                    step="0.01"
                    placeholder="0.00"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group controlId="formReceiverName" className="mb-3">
                  <Form.Label>Nome do Recebedor</Form.Label>
                  <Form.Control
                    type="text"
                    name="receiver_name"
                    value={formData.receiver_name}
                    onChange={handleChange}
                    placeholder="Insira o Nome do Recebedor"
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formAddressLine" className="mb-3">
                  <Form.Label>Endereço</Form.Label>
                  <Form.Control
                    type="text"
                    name="address_line"
                    value={formData.address_line}
                    onChange={handleChange}
                    placeholder="Insira o Endereço"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={4}>
                <Form.Group controlId="formCity" className="mb-3">
                  <Form.Label>Cidade</Form.Label>
                  <Form.Control
                    type="text"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    placeholder="Insira a Cidade"
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="formState" className="mb-3">
                  <Form.Label>Estado</Form.Label>
                  <Form.Control
                    type="text"
                    name="state"
                    value={formData.state}
                    onChange={handleChange}
                    placeholder="Insira o Estado"
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="formZipCode" className="mb-3">
                  <Form.Label>CEP</Form.Label>
                  <Form.Control
                    type="text"
                    name="zip_code"
                    value={formData.zip_code}
                    onChange={handleChange}
                    placeholder="Insira o CEP"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group controlId="formReceiverPhone" className="mb-3">
                  <Form.Label>Telefone</Form.Label>
                  <Form.Control
                    type="text"
                    name="receiver_phone"
                    value={formData.receiver_phone}
                    onChange={handleChange}
                    placeholder="Insira o Telefone"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mt-4">
              <Col className="d-flex justify-content-end">
                <Button variant="primary" type="submit">
                  Salvar
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default SalesForm;
