import React, { useEffect, useState, useRef } from 'react';
import { Alert } from 'react-bootstrap';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import styled, { keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSync,
  faBoxes,
  faInfoCircle,
  faChartBar,
  faPieChart,
} from '@fortawesome/free-solid-svg-icons';
import api from '../../../services/api';

ChartJS.register(ArcElement, Tooltip, Legend);

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const slideIn = keyframes`
  from { transform: translateY(-20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`;

const rotate = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const Container = styled.div`
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  animation: ${fadeIn} 0.5s ease-out;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
`;

const Title = styled.h2`
  color: #343a40;
  text-align: center;
  margin-bottom: 20px;
  animation: ${slideIn} 0.5s ease-out;
`;

const ChartContainer = styled.div`
  height: 300px;
  position: relative;
`;

const LoadingSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 2rem;
  color: #4bc0c0;
  animation: ${rotate} 1s linear infinite;
`;

const RefreshButton = styled.button`
  background-color: #4bc0c0;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 20px;

  &:hover {
    background-color: #3aa;
  }
`;

const StyledAlert = styled(Alert)`
  animation: ${slideIn} 0.5s ease-out;
`;

const InfoIcon = styled(FontAwesomeIcon)`
  margin-left: 10px;
  cursor: pointer;
  color: #17a2b8;
`;

const TooltipContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const TooltipText = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #17a2b8;
  color: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  width: 200px;
  animation: ${fadeIn} 0.3s ease-out;
`;

const CountDisplay = styled.div`
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
  color: #4bc0c0;
  margin: 20px 0;
  animation: ${slideIn} 0.5s ease-out;
`;

const ToggleViewButton = styled.button`
  background-color: #6c757d;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 10px;

  &:hover {
    background-color: #5a6268;
  }
`;

const EnhancedProductCount: React.FC = () => {
  const [productCount, setProductCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [showChart, setShowChart] = useState<boolean>(true);
  const chartRef = useRef<ChartJS<'doughnut', number[], string> | null>(null);

  const fetchProductCount = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await api.get(
        `${import.meta.env.VITE_APP_API_URL}/produtos`
      );

      setProductCount(response.data.meta.total);
    } catch (error) {
      console.error('Erro ao buscar quantidade de produtos:', error);
      setError('Erro ao buscar quantidade de produtos.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProductCount();
  }, []);

  const chartData = {
    labels: ['Produtos', 'Capacidade Restante'],
    datasets: [
      {
        data: [productCount, Math.max(0, 500 - productCount)],
        backgroundColor: [
          'rgba(75, 192, 192, 0.6)',
          'rgba(200, 200, 200, 0.6)',
        ],
        borderColor: ['rgba(75, 192, 192, 1)', 'rgba(200, 200, 200, 1)'],
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: 'Total de Produtos (Capacidade: 500)',
        font: {
          size: 16,
          weight: 'bold' as const,
        },
      },
      legend: {
        display: true,
        position: 'bottom' as const,
      },
    },
    animation: {
      duration: 1000,
      easing: 'easeOutQuart' as const,
    },
  };

  const toggleView = () => {
    setShowChart(!showChart);
  };

  return (
    <Container>
      <Title>
        <FontAwesomeIcon icon={faBoxes} /> Contagem de Produtos
      </Title>
      {loading ? (
        <LoadingSpinner>
          <FontAwesomeIcon icon={faSync} />
        </LoadingSpinner>
      ) : error ? (
        <StyledAlert variant="danger">{error}</StyledAlert>
      ) : (
        <>
          {showChart ? (
            <ChartContainer>
              <Doughnut
                data={chartData}
                options={chartOptions}
                ref={chartRef}
              />
            </ChartContainer>
          ) : (
            <CountDisplay>{productCount}</CountDisplay>
          )}
          <TooltipContainer>
            <InfoIcon
              icon={faInfoCircle}
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
            />
            {showTooltip && (
              <TooltipText>
                Este {showChart ? 'gráfico' : 'número'} representa o total de
                produtos cadastrados no sistema.
              </TooltipText>
            )}
          </TooltipContainer>
          <ToggleViewButton onClick={toggleView}>
            <FontAwesomeIcon icon={showChart ? faPieChart : faChartBar} />
            {showChart ? 'Mostrar Número' : 'Mostrar Gráfico'}
          </ToggleViewButton>
        </>
      )}
      <RefreshButton onClick={fetchProductCount}>
        <FontAwesomeIcon icon={faSync} /> Atualizar Dados
      </RefreshButton>
    </Container>
  );
};

export default EnhancedProductCount;
