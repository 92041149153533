import React from 'react';
import { PermissionWrapper } from '../../components/Permission';
import BuscaPreco from '../../components/BuscaPreco/BuscaPreco';

const CapturaProduto: React.FC = () => {
  return (
    <PermissionWrapper screenId={'8'} permission="access">
      <BuscaPreco />
    </PermissionWrapper>
  );
};

export default CapturaProduto;
