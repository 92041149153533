import React, { useState, useEffect } from 'react';
import {
  FaArrowLeft,
  FaBarcode,
  FaBox,
  FaInfoCircle,
  FaListAlt,
  FaPercent,
  FaPlus,
  FaSave,
  FaSyncAlt,
  FaTag,
} from 'react-icons/fa'; // Importar o ícone
import {
  Button,
  Form,
  Spinner,
  Tooltip,
  OverlayTrigger,
  InputGroup,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../../../services/api';

interface Categoria {
  id: number;
  nome: string;
}

const ProductForm: React.FC = () => {
  const [newProduct, setNewProduct] = useState({
    descricao: '',
    sku: '',
    user_email: sessionStorage.getItem('userEmail') || '',
    categoria_id: '',
    available_quantity: 0,
    meli_product_id: '',
    price: '',
    original_price: '',
    currency_id: '',
    condition: '',
    permalink: '',
    thumbnail: '',
    porcentagem: '', // Adicione o campo porcentagem aqui
  });

  const [categorias, setCategorias] = useState<Categoria[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    const fetchCategorias = async () => {
      try {
        const response = await api.get(
          `${import.meta.env.VITE_APP_API_URL}/categorias`
        );
        setCategorias(response.data.data);
      } catch (error) {
        toast.error('Erro ao carregar categorias.');
        console.error(error);
      }
    };

    if (id) {
      const fetchProduct = async () => {
        try {
          const response = await api.get(
            `${import.meta.env.VITE_APP_API_URL}/produtos/${id}`
          );

          const productData = response.data.data;

          setNewProduct({
            descricao: productData.descricao || '',
            sku: productData.sku || '',
            user_email:
              productData.user_email ||
              sessionStorage.getItem('userEmail') ||
              '',
            categoria_id: productData.categoria_id || '',
            available_quantity: productData.available_quantity || 0,
            meli_product_id: productData.meli_product_id || '',
            price: productData.price || '',
            original_price: productData.original_price || '',
            currency_id: productData.currency_id || '',
            condition: productData.condition || '',
            permalink: productData.permalink || '',
            thumbnail: productData.thumbnail || '',
            porcentagem: productData.porcentagem || '', // Adicione o campo porcentagem aqui
          });
        } catch (error) {
          toast.error('Erro ao carregar os dados do produto.');
          console.error(error);
        } finally {
          setLoading(false);
        }
      };

      fetchProduct();
    } else {
      setLoading(false);
    }

    fetchCategorias();
  }, [id]);

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setNewProduct({ ...newProduct, [name]: value });
  };

  // Função para garantir que a quantidade seja um número inteiro
  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    // Remover todos os caracteres que não são dígitos
    const sanitizedValue = value.replace(/[^\d]/g, '');
    setNewProduct({ ...newProduct, [name]: sanitizedValue });
  };

  const handleCreateOrUpdateProduct = async () => {
    if (!newProduct.descricao || !newProduct.sku) {
      toast.error('Descrição e SKU são obrigatórios.');
      setIsSaving(false);
      return;
    }

    // Verificar se o SKU já existe
    const skuExists = await checkProductBySku(newProduct.sku);

    if (skuExists) {
      if (!id) {
        // Se for uma criação e o SKU já existe, impede a criação
        toast.error(
          'Este SKU já está cadastrado. Por favor, use um SKU diferente.'
        );
        setIsSaving(false);
        return;
      } else {
        // Se for uma edição, verifica se o SKU pertence ao produto sendo editado
        const productResponse = await api.get(
          `${import.meta.env.VITE_APP_API_URL}/produtos/${id}`
        );

        const existingProduct = productResponse.data.data;

        if (existingProduct.sku !== newProduct.sku) {
          // Se o SKU existe e não pertence ao produto atual, impede a atualização
          toast.error(
            'Este SKU já está cadastrado em outro produto. Por favor, use um SKU diferente.'
          );
          setIsSaving(false);
          return;
        }
      }
    }

    setIsSaving(true);

    try {
      let response;

      const formattedPrice = newProduct.price.replace(',', '.');
      const formattedOriginalPrice = newProduct.original_price.replace(
        ',',
        '.'
      );

      if (id) {
        response = await api.put(
          `${import.meta.env.VITE_APP_API_URL}/produtos/${id}`,
          {
            ...newProduct,
            price: formattedPrice,
            original_price: formattedOriginalPrice,
            porcentagem: newProduct.porcentagem, // Inclua porcentagem aqui
          }
        );
        toast.success('Produto atualizado com sucesso!');
      } else {
        response = await api.post(
          `${import.meta.env.VITE_APP_API_URL}/produtos`,
          {
            ...newProduct,
            price: formattedPrice,
            original_price: formattedOriginalPrice,
            porcentagem: newProduct.porcentagem, // Inclua porcentagem aqui
          }
        );
        toast.success('Produto cadastrado com sucesso!');
      }

      const productId = response.data.data.id;

      if (newProduct.available_quantity > 0) {
        const estoqueResponse = await api.get(
          `${
            import.meta.env.VITE_APP_API_URL
          }/estoquelocal/hasEstoque/${productId}`
        );

        if (!estoqueResponse.data.exists) {
          await api.post(`${import.meta.env.VITE_APP_API_URL}/estoques`, {
            produto_id: productId,
            quantidade: newProduct.available_quantity,
            meli_conta_id: 0,
          });
          toast.success('Produto adicionado ao estoque com sucesso!');
        }
      }

      navigate('/produtos');
    } catch (error) {
      console.error('Erro ao salvar produto:', error);
      toast.error('Erro ao salvar produto.');
    } finally {
      setIsSaving(false);
    }
  };

  const checkProductBySku = async (sku: string): Promise<boolean> => {
    try {
      const response = await api.get(
        `${import.meta.env.VITE_APP_API_URL}/produtos/find-by-sku/${sku}`
      );
      return response.data.isok === true; // Retorna `true` se o SKU já existir
    } catch (error) {
      console.error('Erro ao verificar o SKU:', error);
      return false; // Retorna `false` em caso de erro
    }
  };

  const generateSku = () => {
    const randomSku = `SKU-${Math.floor(Math.random() * 1000000)}`;
    setNewProduct({ ...newProduct, sku: randomSku });
  };

  if (loading) {
    return (
      <div className="text-center">
        <Spinner animation="border" role="status">
          <span className="sr-only">...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <div className="container mt-5">
      <div className="d-flex justify-content-center">
        <div
          className="card p-5 shadow rounded-lg"
          style={{ maxWidth: '700px' }}
        >
          <h2 className="text-center mb-4 text-primary">
            {id ? 'Editar Produto' : 'Cadastrar Novo Produto'}
          </h2>

          <Form>
            <Form.Group controlId="descricao" className="mb-4">
              <Form.Label className="font-weight-bold text-secondary">
                <FaTag className="mr-2 text-primary" /> Descrição
              </Form.Label>
              <Form.Control
                type="text"
                name="descricao"
                value={newProduct.descricao}
                onChange={handleInputChange}
                placeholder="Informe a descrição do produto"
                className="p-3 rounded border-light shadow-sm"
              />
            </Form.Group>

            <Form.Group controlId="sku" className="mb-4">
              <Form.Label className="font-weight-bold text-secondary">
                <FaBarcode className="mr-2 text-primary" /> SKU
              </Form.Label>
              <InputGroup>
                <Form.Control
                  type="text"
                  name="sku"
                  value={newProduct.sku}
                  onChange={handleInputChange}
                  placeholder="Informe o SKU do produto"
                  className="p-3 rounded border-light shadow-sm"
                />
                <Button
                  variant="outline-primary"
                  onClick={generateSku}
                  className="shadow-sm no-border-radius"
                  size="sm"
                >
                  <FaSyncAlt /> Gerar SKU
                </Button>
              </InputGroup>
            </Form.Group>

            <Form.Group controlId="categoria_id" className="mb-4">
              <Form.Label className="font-weight-bold text-secondary">
                <FaListAlt className="mr-2 text-primary" /> Categoria
              </Form.Label>
              <Form.Control
                as="select"
                name="categoria_id"
                value={newProduct.categoria_id}
                onChange={handleInputChange}
                className="p-3 rounded border-light shadow-sm"
              >
                <option value="">Selecione uma categoria</option>
                {categorias.map((categoria) => (
                  <option key={categoria.id} value={categoria.id}>
                    {categoria.nome}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="porcentagem" className="mb-4">
              <Form.Label className="font-weight-bold text-secondary">
                <FaPercent className="mr-2 text-primary" /> Porcentagem do
                Produto
              </Form.Label>
              <Form.Control
                type="number"
                name="porcentagem"
                value={newProduct.porcentagem}
                onChange={handleInputChange}
                placeholder="Informe a porcentagem do produto"
                className="p-3 rounded border-light shadow-sm"
              />
            </Form.Group>

            <Form.Group controlId="available_quantity" className="mb-4">
              <Form.Label className="font-weight-bold text-secondary">
                <FaBox className="mr-2 text-primary" /> Quantidade Disponível{' '}
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="available_quantity_tooltip">
                      Se a quantidade for maior que 0, o produto será registrado
                      no estoque caso ainda não exista.
                    </Tooltip>
                  }
                >
                  <span>
                    <FaInfoCircle className="text-info ml-1" size={18} />
                  </span>
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                type="number"
                name="available_quantity"
                value={newProduct.available_quantity}
                onChange={handleQuantityChange}
                placeholder="Informe a quantidade disponível"
                className="p-3 rounded border-light shadow-sm"
              />
            </Form.Group>

            <div className="d-flex justify-content-between mt-4">
              <Button
                variant="primary"
                onClick={handleCreateOrUpdateProduct}
                disabled={isSaving}
                className="px-4 py-2 font-weight-bold shadow-sm rounded"
              >
                {isSaving ? (
                  <Spinner
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : id ? (
                  <>
                    <FaSave className="mr-2" /> Atualizar
                  </>
                ) : (
                  <>
                    <FaPlus className="mr-2" /> Cadastrar
                  </>
                )}
              </Button>

              <Button
                variant="secondary"
                className="px-4 py-2 font-weight-bold shadow-sm rounded"
                onClick={() => navigate('/produtos')}
              >
                <FaArrowLeft className="mr-2" /> Cancelar
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ProductForm;
