import React, { useState, useEffect } from 'react';
import {
  Modal,
  Button,
  Form,
  Table,
  Spinner,
  InputGroup,
  FormControl,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import api from '../../services/api';

interface User {
  id: number;
  name: string;
  email: string;
  created_at: string;
  updated_at: string;
  role: string;
}

interface NewUser {
  name: string;
  email: string;
  password?: string;
  role: string;
  databasename?: string;
}

const AdminPage: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [perPage, setPerPage] = useState<number>(5);
  const [total, setTotal] = useState<number>(0);
  const [to, setTo] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [newUser, setNewUser] = useState<NewUser>({
    name: '',
    email: '',
    password: '',
    role: 'user',
  });
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const database = sessionStorage.getItem('database');
  const navigate = useNavigate();
  const handleProfileClick = (userId: number) => {
    navigate(`/permissions/${userId}`);
  };

  useEffect(() => {
    if (database) {
      fetchUsers(currentPage);
    } else {
      toast.error('Informações de autenticação não encontradas.');
      setLoading(false);
    }
  }, [currentPage, perPage]);

  const fetchUsers = async (page: number, searchQuery: string = '') => {
    if (!database) {
      toast.error(
        'Token ou database não encontrado. Por favor, faça login novamente.'
      );
      setLoading(false);
      return;
    }

    setLoading(true);

    try {
      let url = `${
        import.meta.env.VITE_APP_API_URL
      }/users?databasename=${database}&page=${page}&per_page=${perPage}`;
      if (searchQuery) {
        url += `&search=${searchQuery}`;
      }

      const response = await api.get(url);

      setUsers(response.data.data);
      setCurrentPage(response.data.meta.current_page);
      setTotalPages(response.data.meta.last_page);
      setPerPage(response.data.meta.per_page);
      setTotal(response.data.meta.total);
      setTo(response.data.meta.to);
    } catch (error) {
      console.error('Erro ao buscar usuários:', error);
      toast.error('Erro ao buscar usuários.');
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (newPage: number) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleSearchKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      fetchUsers(1, search);
    }
  };

  const handleCreateModalClose = () => {
    setShowCreateModal(false);
    setNewUser({ name: '', email: '', password: '', role: 'user' });
    setIsEditing(false);
  };

  const handleDeleteModalShow = (user: User) => {
    setSelectedUser(user);
    setShowDeleteModal(true);
  };

  const handleDeleteModalClose = () => {
    setShowDeleteModal(false);
    setSelectedUser(null);
  };

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setNewUser({ ...newUser, [name]: value });
  };

  const handleCreateOrUpdateUser = async () => {
    if (!database) {
      toast.error(
        'Token ou database não encontrado. Por favor, faça login novamente.'
      );
      setIsSaving(false);
      return;
    }

    if (!newUser.name || !newUser.email || !newUser.role) {
      toast.error('Nome, E-mail e Papel são obrigatórios.');
      setIsSaving(false);
      return;
    }

    setIsSaving(true);

    try {
      const payload: NewUser = {
        name: newUser.name,
        email: newUser.email,
        role: newUser.role,
        databasename: database,
      };
      if (newUser.password) {
        payload.password = newUser.password;
      }

      if (isEditing && selectedUser) {
        await api.put(
          `${import.meta.env.VITE_APP_API_URL}/users/${selectedUser.id}`,
          payload
        );
        toast.success('Edição feita com sucesso!');
      } else {
        await api.post(`${import.meta.env.VITE_APP_API_URL}/users`, payload);
        toast.success('Registro salvo com sucesso!');
      }

      handleCreateModalClose();
      fetchUsers(currentPage);
    } catch (error: any) {
      console.error('Erro ao salvar usuário:', error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Erro ao salvar usuário.');
      }
    } finally {
      setIsSaving(false);
    }
  };

  const handleEditUser = (user: User) => {
    setSelectedUser(user);
    setNewUser({
      name: user.name,
      email: user.email,
      password: '',
      role: user.role,
    });
    setIsEditing(true);
    setShowCreateModal(true);
  };

  const handleDeleteUser = async () => {
    setIsSaving(true);

    try {
      if (selectedUser) {
        await api.delete(
          `${import.meta.env.VITE_APP_API_URL}/users/${selectedUser.id}`
        );

        handleDeleteModalClose();
        fetchUsers(currentPage);
      }
    } catch (error) {
      console.error('Erro ao excluir usuário:', error);
      toast.error('Erro ao excluir usuário.');
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="container-fluid">
      <h1 className="text-center mb-4">Usuários</h1>
      <div className="d-flex flex-wrap justify-content-between align-items-center mb-3">
        <Button
          variant="success"
          onClick={() => setShowCreateModal(true)}
          className="mr-2 mb-2"
        >
          Cadastrar Usuário
        </Button>
        <div className="d-flex flex-grow-1 align-items-center mr-2 mb-2">
          <InputGroup className="flex-grow-1">
            <FormControl
              placeholder="Pesquisar"
              aria-label="Pesquisar"
              value={search}
              onChange={handleSearchChange}
              onKeyDown={handleSearchKeyPress}
              className="w-100"
            />
          </InputGroup>
        </div>
      </div>

      {loading ? (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="sr-only">@</span>
          </Spinner>
        </div>
      ) : (
        <>
          <div className="table-responsive">
            <Table hover className="table-striped" id="table-to-print">
              <thead className="thead-dark table table-dark">
                <tr>
                  <th>ID</th>
                  <th>Nome</th>
                  <th>E-mail</th>
                  <th>Data de Criação</th>
                  <th>Última Atualização</th>
                  <th>Papel</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user) => (
                  <tr key={user.id}>
                    <td>{user.id}</td>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td>{new Date(user.created_at).toLocaleDateString()}</td>
                    <td>{new Date(user.updated_at).toLocaleDateString()}</td>
                    <td>{user.role}</td>
                    <td>
                      <Button
                        variant="primary"
                        size="sm"
                        className="mr-2"
                        onClick={() => handleEditUser(user)}
                      >
                        Editar
                      </Button>
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => handleDeleteModalShow(user)}
                      >
                        Excluir
                      </Button>
                      <Button
                        variant="info"
                        size="sm"
                        onClick={() => handleProfileClick(user.id)}
                      >
                        Perfil
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <br />
          <p className="text-muted">
            Mostrando {to} de {total} usuários
          </p>
          <nav>
            <ul className="pagination justify-content-center">
              <li
                className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}
              >
                <button
                  className="page-link"
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  Anterior
                </button>
              </li>
              {Array.from({ length: totalPages }, (_, index) => index + 1).map(
                (pageNumber) => (
                  <li
                    key={pageNumber}
                    className={`page-item ${
                      currentPage === pageNumber ? 'active' : ''
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(pageNumber)}
                    >
                      {pageNumber}
                    </button>
                  </li>
                )
              )}
              <li
                className={`page-item ${
                  currentPage === totalPages ? 'disabled' : ''
                }`}
              >
                <button
                  className="page-link"
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  Próxima
                </button>
              </li>
            </ul>
          </nav>
        </>
      )}

      <Modal show={showCreateModal} onHide={handleCreateModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {isEditing ? 'Editar Usuário' : 'Cadastrar Novo Usuário'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="name">
              <Form.Label>Nome</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={newUser.name}
                onChange={handleInputChange}
                placeholder="Informe o nome do usuário"
              />
            </Form.Group>
            <Form.Group controlId="email">
              <Form.Label>E-mail</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={newUser.email}
                onChange={handleInputChange}
                placeholder="Informe o e-mail do usuário"
              />
            </Form.Group>
            <Form.Group controlId="password">
              <Form.Label>Senha</Form.Label>
              <Form.Control
                type="password"
                name="password"
                value={newUser.password}
                onChange={handleInputChange}
                placeholder="Informe a senha"
              />
            </Form.Group>
            <Form.Group controlId="role">
              <Form.Label>Papel</Form.Label>
              <Form.Control
                as="select"
                name="role"
                value={newUser.role}
                onChange={handleInputChange}
              >
                <option value="user">User</option>
                <option value="admin">Admin</option>
                <option value="busca">Modulo Busca Preço Mercado Livre</option>
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCreateModalClose}>
            Cancelar
          </Button>
          <Button
            variant="primary"
            onClick={handleCreateOrUpdateUser}
            disabled={isSaving}
          >
            {isSaving ? (
              <Spinner
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : isEditing ? (
              'Atualizar'
            ) : (
              'Cadastrar'
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDeleteModal} onHide={handleDeleteModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar Exclusão</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Tem certeza de que deseja excluir o usuário {selectedUser?.name}?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDeleteModalClose}>
            Cancelar
          </Button>
          <Button
            variant="danger"
            onClick={handleDeleteUser}
            disabled={isSaving}
          >
            {isSaving ? (
              <Spinner
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              'Excluir'
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AdminPage;
