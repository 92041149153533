import React, { useEffect, useState } from 'react';
import AppNavbar from '../components/AppNavbar'; // Atualize o caminho conforme sua estrutura de pastas
import BuscaPreco from '../../../components/BuscaPreco/BuscaPreco';
import TrialCheck from '../../../components/TrialCheck';

const Busca: React.FC = () => {
  const [isReloaded, setIsReloaded] = useState(false);

  useEffect(() => {
    // Verifica o contador de recarregamentos na sessão
    let reloadCount = parseInt(
      sessionStorage.getItem('reloadCount') || '0',
      10
    );

    if (reloadCount < 1) {
      // Incrementa o contador de recarregamentos
      reloadCount += 1;
      sessionStorage.setItem('reloadCount', reloadCount.toString());

      // Recarrega a página
      window.location.reload();
    } else {
      // Sinaliza que a página já foi recarregada
      setIsReloaded(true);
    }
  }, []);

  return (
    <>
      <AppNavbar />
      {isReloaded && (
        <TrialCheck>
          <BuscaPreco />
        </TrialCheck>
      )}
    </>
  );
};

export default Busca;
