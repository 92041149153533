import React, { useState, useEffect } from 'react';
import {
  Table,
  Button,
  Spinner,
  InputGroup,
  FormControl,
  FormSelect,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import ExcelJS from 'exceljs';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { formatCurrency } from '../../utils/formatCurrency';
import CalculoModal from './componentes/CalculoModal';
import CalculoSugestao2Modal from './componentes/CalculoSugestao2Modal';
import api from '../../services/api';

const RelatorioVendas: React.FC = () => {
  const [vendas, setVendas] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [perPage, setPerPage] = useState<number>(15);
  const [total, setTotal] = useState<number>(0);
  const [to, setTo] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const [margemDesejada, setMargemDesejada] = useState<number>(5); // Margem padrão de 5%
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState(false);
  const [showSugestao2Modal, setShowSugestao2Modal] = useState(false);
  const [modalData, setModalData] = useState({
    precoCusto: 0,
    tarifaVenda: 0,
    custoEnvio: 0,
    imposto: 0,
    margemDesejada: 0,
    porcentagemProduto: 0,
    precoSugerido: 0,
  });
  const [sugestao2ModalData, setSugestao2ModalData] = useState({
    valorTotalPago: 0,
    margemAtual: 0,
    margemDesejada: 0,
    precoSugestao2: 0,
  });

  useEffect(() => {
    fetchVendas(currentPage);
  }, [currentPage, perPage]);

  const handlePageChange = (newPage: number) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleSearchKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      fetchVendas(1, search);
    }
  };

  const handleMargemChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setMargemDesejada(Number(e.target.value));
  };

  const handlePerPageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const exportToExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Vendas');

    // Definindo as colunas do cabeçalho
    worksheet.columns = [
      { header: 'ID Venda', key: 'id_venda', width: 15 },
      { header: 'Data Criação', key: 'data_criacao', width: 15 },
      { header: 'Título', key: 'titulo_item', width: 30 },
      { header: 'SKU', key: 'sku_vendedor', width: 15 },
      { header: 'Quantidade', key: 'quantidade', width: 15 },
      { header: 'Valor Total Pago', key: 'valor_total_pago', width: 20 },
      { header: 'Tarifa Venda', key: 'tarifa_venda', width: 20 },
      { header: 'Custo Envio', key: 'custo_envio', width: 20 },
      { header: 'Tipo Anúncio', key: 'tipo_anuncio', width: 15 },
      { header: 'Total(BRL)', key: 'total', width: 20 },
      { header: 'Conta', key: 'nome_conta', width: 20 },
      { header: 'RS Compra', key: 'rs_compra', width: 20 },
      { header: 'RS Recebido', key: 'rs_recebido', width: 20 },
      { header: 'Ganho Recebido', key: 'rs_ganho_recebido', width: 20 },
      { header: 'Imposto', key: 'imposto', width: 15 },
      { header: 'Valor Imposto', key: 'valor_imposto', width: 20 },
      { header: 'Ganho c/ Imposto', key: 'ganho_com_imposto', width: 20 },
      { header: 'Margem', key: 'margem', width: 15 },
      { header: 'Status Margem', key: 'status_margem', width: 20 },
      { header: 'Preço Sugerido', key: 'preco_sugerido', width: 20 },
      {
        header: 'Preço Sugestão Venda Final',
        key: 'preco_sugestao_2',
        width: 20,
      },
    ];

    // Adicionando dados e aplicando formatação condicional
    vendas.forEach((venda) => {
      worksheet.addRow({
        ...venda,
        preco_sugerido: calcularPrecoSugerido(
          venda.rs_compra,
          venda.tarifa_venda,
          venda.custo_envio,
          venda.valor_imposto,
          margemDesejada,
          venda.porcentagem
        ),
        preco_sugestao_2: calcularPrecoSugestao2(
          venda.valor_total_pago,
          margemDesejada,
          venda.margem
        ),
        status_margem: venda.margem >= margemDesejada ? 'Positivo' : 'Negativo',
      });
    });

    // Aplicando formatação de moeda às colunas
    const currencyColumns = [
      'valor_total_pago',
      'tarifa_venda',
      'custo_envio',
      'total',
      'rs_compra',
      'rs_recebido',
      'rs_ganho_recebido',
      'ganho_com_imposto',
      'preco_sugerido',
      'preco_sugestao_2',
    ];

    currencyColumns.forEach((columnKey) => {
      worksheet.getColumn(columnKey).eachCell((cell) => {
        if (typeof cell.value === 'number') {
          cell.numFmt = '"R$ "#,##0.00';
        } else if (
          typeof cell.value === 'string' &&
          !isNaN(parseFloat(cell.value))
        ) {
          cell.value = parseFloat(cell.value);
          cell.numFmt = '"R$ "#,##0.00';
        }
      });
    });

    // Estilizando cabeçalho
    worksheet.getRow(1).font = { bold: true, color: { argb: 'FFFFFF' } };
    worksheet.getRow(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '000000' },
    };

    // Estilizando Status Margem
    worksheet.eachRow((row, rowNumber) => {
      if (rowNumber > 1) {
        const margemCell = row.getCell('status_margem');
        if (margemCell.value === 'Positivo') {
          margemCell.font = { color: { argb: '008000' } }; // Verde
        } else if (margemCell.value === 'Negativo') {
          margemCell.font = { color: { argb: 'FF0000' } }; // Vermelho
        }
      }
    });

    // Salvando o arquivo Excel
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'relatorio_vendas.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const exportToPDF = async () => {
    setExportLoading(true);
    try {
      const pdf = new jsPDF('landscape');
      const title = 'Relatório de Vendas Margem Final';
      const tableColumn = [
        'ID Venda',
        'Data Criação',
        'Título',
        'SKU',
        'Quantidade',
        'Total(BRL)',
        'Conta',
        'Margem',
        'Status Margem',
        'Preço Sugerido',
        'Preço Sugestão Venda Final',
      ];
      const tableRows: any[] = [];

      vendas.forEach((venda) => {
        const vendaData = [
          venda.id_venda,
          new Date(venda.data_criacao).toLocaleDateString(),
          venda.titulo_item,
          venda.sku_vendedor,
          venda.quantidade,
          formatCurrency(venda.total),
          venda.nome_conta,
          venda.margem + '%',
          {
            content: venda.margem >= margemDesejada ? 'Positivo' : 'Negativo',
            styles: {
              textColor: venda.margem >= margemDesejada ? 'green' : 'red',
            },
          },
          formatCurrency(
            calcularPrecoSugerido(
              venda.rs_compra,
              venda.tarifa_venda,
              venda.custo_envio,
              venda.valor_imposto,
              margemDesejada,
              venda.porcentagem
            )
          ),
          formatCurrency(
            calcularPrecoSugestao2(
              venda.valor_total_pago,
              margemDesejada,
              venda.margem
            )
          ),
        ];
        tableRows.push(vendaData);
      });

      pdf.setFontSize(18);
      pdf.text(title, 13, 8);
      pdf.setFontSize(12);
      pdf.text('Data: ' + new Date().toLocaleDateString(), 240, 8); // Ajuste para paisagem

      autoTable(pdf, {
        head: [tableColumn],
        body: tableRows,
        startY: 15, // Ajuste a posição de início para paisagem
        theme: 'grid',
        styles: { fontSize: 8, cellPadding: 2 },
        headStyles: { fillColor: [0, 0, 0], textColor: [255, 255, 255] },
      });

      pdf.save('relatorio_vendas.pdf');
      alert('Exportação para PDF realizada com sucesso!');
    } catch (error) {
      console.error('Erro ao exportar para PDF:', error);
      alert('Erro ao exportar para PDF.');
    } finally {
      setExportLoading(false);
    }
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 5;
    const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);
    let startPage = Math.max(1, currentPage - halfMaxPagesToShow);
    let endPage = Math.min(totalPages, currentPage + halfMaxPagesToShow);

    if (currentPage <= halfMaxPagesToShow) {
      endPage = Math.min(totalPages, maxPagesToShow);
    } else if (currentPage + halfMaxPagesToShow >= totalPages) {
      startPage = Math.max(1, totalPages - maxPagesToShow + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  const calcularPrecoSugerido = (
    precoCusto: number,
    tarifaVenda: number,
    custoEnvio: number,
    imposto: number,
    margemDesejada: number,
    porcentagemProduto: number
  ): number => {
    // Remover formatação de moeda e converter para número
    const precoCustoNum = parseFloat(
      precoCusto.toString().replace(/[^\d,.-]/g, '')
    );
    const tarifaVendaNum = parseFloat(
      tarifaVenda.toString().replace(/[^\d,.-]/g, '')
    );
    const custoEnvioNum = parseFloat(
      custoEnvio.toString().replace(/[^\d,.-]/g, '')
    );
    const impostoNum =
      parseFloat(imposto.toString().replace(/[^\d,.-]/g, '')) / 100;

    // Calcular o preço sugerido
    const precoSugerido =
      (precoCustoNum + tarifaVendaNum + custoEnvioNum) /
      (1 - (impostoNum + margemDesejada / 100 + porcentagemProduto / 100));

    return precoSugerido;
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowModal = (venda: any) => {
    const precoSugerido = calcularPrecoSugerido(
      venda.rs_compra,
      venda.tarifa_venda,
      venda.custo_envio,
      venda.valor_imposto,
      margemDesejada,
      venda.porcentagem
    );

    setModalData({
      precoCusto: venda.rs_compra,
      tarifaVenda: venda.tarifa_venda,
      custoEnvio: venda.custo_envio,
      imposto: venda.valor_imposto,
      margemDesejada,
      porcentagemProduto: venda.porcentagem,
      precoSugerido,
    });

    setShowModal(true);
  };

  const calcularPrecoSugestao2 = (
    valorTotalPago: number,
    margemDesejada: number,
    margemAtual: number
  ): number => {
    // Remover formatação de moeda e converter para número
    const valorTotalPagoNum = parseFloat(
      valorTotalPago.toString().replace(/[^\d,.-]/g, '')
    );
    const margemDesejadaNum = parseFloat(
      margemDesejada.toString().replace(/[^\d,.-]/g, '')
    );
    const margemAtualNum = parseFloat(
      margemAtual.toString().replace(/[^\d,.-]/g, '')
    );

    // Verifica se algum dos valores não foi convertido corretamente
    if (
      isNaN(valorTotalPagoNum) ||
      isNaN(margemDesejadaNum) ||
      isNaN(margemAtualNum)
    ) {
      return 0; // Retorna 0 ou algum valor padrão se a conversão falhar
    }

    // Calcular o preço de sugestão 2
    const diferencaMargem = margemDesejadaNum - margemAtualNum;
    const ajuste = valorTotalPagoNum * (diferencaMargem / 100);
    const precoFinal = valorTotalPagoNum + ajuste;

    return precoFinal;
  };

  const handleShowSugestao2Modal = (venda: any) => {
    const precoSugestao2 = calcularPrecoSugestao2(
      venda.valor_total_pago,
      margemDesejada,
      venda.margem
    );

    setSugestao2ModalData({
      valorTotalPago: venda.valor_total_pago,
      margemAtual: venda.margem,
      margemDesejada,
      precoSugestao2,
    });

    setShowSugestao2Modal(true);
  };

  const handleCloseSugestao2Modal = () => {
    setShowSugestao2Modal(false);
  };

  const fetchVendas = async (page: number, searchQuery: string = '') => {
    setLoading(true);

    try {
      let url = `${
        import.meta.env.VITE_APP_API_URL
      }/vendasRel?page=${page}&per_page=${perPage}`;
      if (searchQuery) {
        url += `&search=${searchQuery}`;
      }

      const response = await api.get(url, {});

      setVendas(response.data.data);
      setCurrentPage(response.data.meta.current_page);
      setTotalPages(response.data.meta.last_page);
      setPerPage(response.data.meta.per_page);
      setTotal(response.data.meta.total);
      setTo(response.data.meta.to);
    } catch (error) {
      console.error('Erro ao buscar vendas:', error);
      alert('Erro ao buscar vendas.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container-fluid">
      <h1 className="text-center mb-4">Relatório de Vendas</h1>
      <div className="d-flex flex-wrap justify-content-between align-items-center mb-3">
        <div className="d-flex flex-grow-1 align-items-center mr-2 mb-2">
          <InputGroup className="flex-grow-1">
            <FormControl
              placeholder="Pesquisar por Comprador, Título, SKU..."
              aria-label="Pesquisar"
              value={search}
              onChange={handleSearchChange}
              onKeyDown={handleSearchKeyPress}
              className="w-100"
            />
          </InputGroup>
        </div>
        <div className="d-flex align-items-center">
          <FormSelect
            value={margemDesejada}
            onChange={handleMargemChange}
            className="mb-2"
            style={{ width: '170px' }}
          >
            <option value={5}>Margem de 5%</option>
            <option value={10}>Margem de 10%</option>
            <option value={15}>Margem de 15%</option>
            <option value={20}>Margem de 20%</option>
            <option value={25}>Margem de 25%</option>
            <option value={30}>Margem de 30%</option>
          </FormSelect>
          <Button
            variant="info"
            onClick={exportToExcel}
            className="mr-2 mb-2"
            disabled={exportLoading}
          >
            {exportLoading ? (
              <Spinner
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <>
                <FontAwesomeIcon icon={faFileExcel} /> Exportar para Excel
              </>
            )}
          </Button>
          <Button
            variant="warning"
            onClick={exportToPDF}
            className="mr-2 mb-2"
            disabled={exportLoading}
          >
            {exportLoading ? (
              <Spinner
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <>
                <FontAwesomeIcon icon={faFilePdf} /> Exportar para PDF
              </>
            )}
          </Button>
          <FormSelect
            value={perPage}
            onChange={handlePerPageChange}
            className="mb-2"
            style={{ width: '100px' }}
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={15}>15</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </FormSelect>
        </div>
      </div>

      {loading ? (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="sr-only">Carregando...</span>
          </Spinner>
        </div>
      ) : (
        <>
          <div className="table-responsive">
            <Table hover className="table-striped" id="table-to-print">
              <thead className="thead-dark table table-dark">
                <tr>
                  <th>ID</th>
                  <th>ID Venda</th>
                  <th>Data Criação</th>
                  <th>Comprador</th>
                  <th>Título</th>
                  <th>SKU</th>
                  <th>Quantidade</th>
                  <th>Valor Total Pago</th>
                  <th>Tarifa Venda</th>
                  <th>Custo Envio</th>
                  <th>Tipo Anúncio</th>
                  <th>Status Entrega</th>
                  <th>Total</th>
                  <th>Nome Destinatário</th>
                  <th>Endereço</th>
                  <th>Cidade</th>
                  <th>Estado</th>
                  <th>CEP</th>
                  <th>Telefone</th>
                  <th>Conta Meli</th>
                  <th>RS Compra</th>
                  <th>RS Recebido</th>
                  <th>Ganho Recebido</th>
                  <th>Imposto</th>
                  <th>Valor Imposto</th>
                  <th>Ganho c/ Imposto</th>
                  <th>Margem</th>
                  <th>Status Margem</th>
                  <th>Porcentagem do Produto</th>
                  <th>Preço Sugerido Bruto</th>
                  <th>Preço Sugerido Venda Final</th>
                </tr>
              </thead>
              <tbody>
                {vendas.map((venda) => (
                  <tr key={venda.id}>
                    <td>{venda.id}</td>
                    <td>{venda.id_venda}</td>
                    <td>{new Date(venda.data_criacao).toLocaleDateString()}</td>
                    <td>{venda.apelido_comprador}</td>
                    <td>{venda.titulo_item}</td>
                    <td>{venda.sku_vendedor}</td>
                    <td>{venda.quantidade}</td>
                    <td>{formatCurrency(venda.valor_total_pago)}</td>
                    <td>{formatCurrency(venda.tarifa_venda)}</td>
                    <td>{formatCurrency(venda.custo_envio)}</td>
                    <td>{venda.tipo_anuncio}</td>
                    <td>{venda.status_entrega}</td>
                    <td>{formatCurrency(venda.total)}</td>
                    <td>{venda.nome_destinatario}</td>
                    <td>{venda.endereco}</td>
                    <td>{venda.cidade}</td>
                    <td>{venda.estado}</td>
                    <td>{venda.cep}</td>
                    <td>{venda.telefone_destinatario}</td>
                    <td>{venda.nome_conta}</td>
                    <td>{formatCurrency(venda.rs_compra)}</td>
                    <td>{formatCurrency(venda.rs_recebido)}</td>
                    <td>{formatCurrency(venda.rs_ganho_recebido)}</td>
                    <td>{venda.imposto}%</td>
                    <td>{venda.valor_imposto}%</td>
                    <td>{formatCurrency(venda.ganho_com_imposto)}</td>
                    <td>{venda.margem}%</td>
                    <td
                      style={{
                        color: venda.margem >= margemDesejada ? 'green' : 'red',
                      }}
                    >
                      {venda.margem >= margemDesejada ? 'Positivo' : 'Negativo'}
                    </td>
                    <td>{venda.porcentagem}</td>
                    <td>
                      {venda.margem < margemDesejada ? (
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            handleShowModal(venda);
                          }}
                        >
                          {formatCurrency(
                            calcularPrecoSugerido(
                              venda.rs_compra,
                              venda.tarifa_venda,
                              venda.custo_envio,
                              venda.valor_imposto,
                              margemDesejada,
                              venda.porcentagem
                            )
                          )}
                        </a>
                      ) : (
                        'N/A'
                      )}
                    </td>
                    <td>
                      {venda.margem < margemDesejada ? (
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            handleShowSugestao2Modal(venda);
                          }}
                        >
                          {formatCurrency(
                            calcularPrecoSugestao2(
                              venda.valor_total_pago,
                              margemDesejada,
                              venda.margem
                            )
                          )}
                        </a>
                      ) : (
                        'N/A'
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <br />
          <p className="text-muted">
            Mostrando {to} de {total} vendas
          </p>
          <nav>
            <ul className="pagination justify-content-center">
              <li
                className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}
              >
                <button
                  className="page-link"
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  Anterior
                </button>
              </li>
              {getPageNumbers().map((pageNumber) => (
                <li
                  key={pageNumber}
                  className={`page-item ${
                    currentPage === pageNumber ? 'active' : ''
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(pageNumber)}
                  >
                    {pageNumber}
                  </button>
                </li>
              ))}
              <li
                className={`page-item ${
                  currentPage === totalPages ? 'disabled' : ''
                }`}
              >
                <button
                  className="page-link"
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  Próxima
                </button>
              </li>
            </ul>
          </nav>
        </>
      )}

      <CalculoModal
        show={showModal}
        handleClose={handleCloseModal}
        precoCusto={modalData.precoCusto}
        tarifaVenda={modalData.tarifaVenda}
        custoEnvio={modalData.custoEnvio}
        imposto={modalData.imposto}
        margemDesejada={modalData.margemDesejada}
        porcentagemProduto={modalData.porcentagemProduto}
        precoSugerido={modalData.precoSugerido}
      />

      <CalculoSugestao2Modal
        show={showSugestao2Modal}
        handleClose={handleCloseSugestao2Modal}
        valorTotalPago={sugestao2ModalData.valorTotalPago}
        margemDesejada={sugestao2ModalData.margemDesejada}
        margemAtual={sugestao2ModalData.margemAtual}
        precoSugestao2={sugestao2ModalData.precoSugestao2}
      />
    </div>
  );
};

export default RelatorioVendas;
