import React, { useState, useEffect } from 'react';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSun,
  faMoon,
  faChartLine,
  faBoxes,
} from '@fortawesome/free-solid-svg-icons';
import { PermissionWrapper } from '../../components/Permission';
import { formatCurrency } from '../../utils';
import { getMonthName } from '../../utils/formatCurrency';
import ProductCount from './components/ProductCount';
import BiEstoqueMin from './components/BiEstoqueMin';
import BiEstoqueMax from './components/BiEstoqueMax';
import api from '../../services/api';
import BiVendas from './components/BiVendas';

const lightTheme = {
  background: '#f0f2f5',
  text: '#333',
  cardBg: '#fff',
  primaryColor: '#3498db',
  secondaryColor: '#2ecc71',
};

const darkTheme = {
  background: '#1a1a1a',
  text: '#f0f0f0',
  cardBg: '#2c2c2c',
  primaryColor: '#3498db',
  secondaryColor: '#2ecc71',
};

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${(props) => props.theme.background};
    color: ${(props) => props.theme.text};
    transition: all 0.3s ease;
  }
`;

const DashboardContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

const Title = styled.h1`
  font-size: 2rem;
  color: ${(props) => props.theme.primaryColor};
`;

const ThemeToggle = styled.button`
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: ${(props) => props.theme.text};
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
`;

const Tab = styled.button<{ $active: boolean }>`
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  border: none;
  background-color: ${(props) =>
    props.$active ? props.theme.primaryColor : 'transparent'};
  color: ${(props) => (props.$active ? '#fff' : props.theme.text)};
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${(props) => props.theme.primaryColor};
    color: #fff;
  }
`;

const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
  margin-bottom: 2rem;
`;

const Card = styled(motion.div)`
  background-color: ${(props) => props.theme.cardBg};
  border-radius: 10px;
  padding: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const QuickSummary = styled(Card)`
  grid-column: 1 / -1;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const SummaryItem = styled.div`
  text-align: center;
`;

const SummaryValue = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  color: ${(props) => props.theme.primaryColor};
`;

const SummaryLabel = styled.div`
  font-size: 0.9rem;
  color: ${(props) => props.theme.text};
`;

const EnhancedHome: React.FC = () => {
  const [theme, setTheme] = useState('light');
  const [activeTab, setActiveTab] = useState('overview');
  const [biData, setBiData] = useState({
    total_mensal: 0,
    total_semanal: 0,
    total_diario: 0,
    mes_atual: '',
  });
  useEffect(() => {
    // Fetch data from API
    const fetchData = async () => {
      try {
        const response = await api.get(
          `${import.meta.env.VITE_APP_API_URL}/bi_maxVendas`
        );
        const data = response.data.data[0];
        setBiData({
          total_mensal: data.total_mensal,
          total_semanal: data.total_semanal,
          total_diario: data.total_diario,
          mes_atual: data.mes_atual,
        });
      } catch (error) {
        console.error('Erro ao buscar dados BI', error);
      }
    };

    fetchData();
  }, []);

  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <PermissionWrapper screenId={'3'} permission="access">
      <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
        <GlobalStyle />
        <DashboardContainer>
          <Header>
            <Title>Dashboard de Vendas e Estoque</Title>
            <ThemeToggle onClick={toggleTheme}>
              <FontAwesomeIcon icon={theme === 'light' ? faMoon : faSun} />
            </ThemeToggle>
          </Header>

          <QuickSummary
            initial="hidden"
            animate="visible"
            variants={cardVariants}
          >
            <SummaryItem>
              <SummaryValue>{getMonthName(biData.mes_atual)}</SummaryValue>
              <SummaryLabel>Mês Atual</SummaryLabel>
            </SummaryItem>
            <SummaryItem>
              <SummaryValue>
                {formatCurrency(biData?.total_mensal?.toString() || '0')}
              </SummaryValue>
              <SummaryLabel>Total Mensal</SummaryLabel>
            </SummaryItem>
            <SummaryItem>
              <SummaryValue>
                {' '}
                {formatCurrency(biData?.total_semanal?.toString() || '0')}
              </SummaryValue>
              <SummaryLabel>Total Semanal</SummaryLabel>
            </SummaryItem>
            <SummaryItem>
              <SummaryValue>
                {formatCurrency(biData?.total_diario?.toString() || '0')}
              </SummaryValue>
              <SummaryLabel>Total Diário</SummaryLabel>
            </SummaryItem>
          </QuickSummary>
          <br />
          <TabContainer>
            <Tab
              $active={activeTab === 'overview'}
              onClick={() => setActiveTab('overview')}
            >
              <FontAwesomeIcon icon={faChartLine} /> Visão Geral
            </Tab>

            <Tab
              $active={activeTab === 'stock'}
              onClick={() => setActiveTab('stock')}
            >
              <FontAwesomeIcon icon={faBoxes} /> Estoque
            </Tab>
          </TabContainer>

          <AnimatePresence mode="wait">
            {activeTab === 'overview' && (
              <motion.div
                key="overview"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <CardGrid>
                  <Card variants={cardVariants}>
                    <ProductCount />
                  </Card>
                  <Card variants={cardVariants}>
                    <BiVendas />
                  </Card>
                </CardGrid>
              </motion.div>
            )}

            {activeTab === 'stock' && (
              <motion.div
                key="stock"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <CardGrid>
                  <Card variants={cardVariants}>
                    <BiEstoqueMin />
                  </Card>
                  <Card variants={cardVariants}>
                    <BiEstoqueMax />
                  </Card>
                </CardGrid>
              </motion.div>
            )}
          </AnimatePresence>
        </DashboardContainer>
      </ThemeProvider>
    </PermissionWrapper>
  );
};

export default EnhancedHome;
