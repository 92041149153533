import React from 'react';
import styled from 'styled-components';
import { Product } from '../../../components/types';

interface LocalProductDetailsProps {
  product: Product | null;
}

const DetailsContainer = styled.div`
  padding: 20px;
`;

const InfoTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: 0 auto;

  td {
    padding: 12px 15px;
    border: 1px solid #ddd;
  }

  tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  strong {
    color: #333;
  }

  @media (max-width: 600px) {
    td {
      display: block;
      width: 100%;
    }

    tr {
      margin-bottom: 15px;
      display: block;
    }
  }
`;

const LocalProductDetails: React.FC<LocalProductDetailsProps> = ({
  product,
}) => {
  if (!product) return null;

  return (
    <DetailsContainer>
      <InfoTable>
        <tbody>
          <tr>
            <td>
              <strong>ID:</strong>
            </td>
            <td>{product.id}</td>
          </tr>
          <tr>
            <td>
              <strong>Descrição:</strong>
            </td>
            <td>{product.descricao}</td>
          </tr>
          <tr>
            <td>
              <strong>SKU:</strong>
            </td>
            <td>{product.sku}</td>
          </tr>
          <tr>
            <td>
              <strong>Última Atualização:</strong>
            </td>
            <td>{new Date(product.updated_at).toLocaleDateString()}</td>
          </tr>
          <tr>
            <td>
              <strong>Usuário:</strong>
            </td>
            <td>{product.user_email}</td>
          </tr>
        </tbody>
      </InfoTable>
    </DetailsContainer>
  );
};

export default LocalProductDetails;
