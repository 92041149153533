import React, { useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import {
  FaSignOutAlt,
  FaHome,
  FaNewspaper,
  FaCalculator,
  FaKey,
  FaBars,
  FaCrown,
} from 'react-icons/fa';
import { useAuth } from '../../../components/Private/AuthContext';
import ChangePasswordModal from './ChangePasswordModal';

// Estilos globais
const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Arial', sans-serif;
  }
`;

// Navbar estilizada
const Navbar = styled.nav`
  background-color: #2d2f33;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  z-index: 1000;
  padding: 0 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Brand = styled.a`
  color: #fff;
  text-decoration: none;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  align-items: center;

  img {
    width: 120px;
    height: auto;
    margin-right: 10px;
  }
`;

const Hamburger = styled(FaBars)`
  font-size: 24px;
  cursor: pointer;
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
`;

interface NavLinksProps {
  isOpen: boolean;
}

const NavLinks = styled.div<NavLinksProps>`
  display: flex;
  align-items: center;

  a {
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 10px 15px;
    margin-right: 10px;
    border-radius: 20px;
    text-decoration: none;
    color: white;
    background-color: #5bc0de;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #46a2c6;
    }

    &.warning {
      background-color: #f0ad4e;
    }

    &.primary {
      background-color: #0275d8;
    }

    &.danger {
      background-color: #d9534f;
    }

    &.vip {
      background-color: #ffcc00; /* Cor dourada para o plano VIP */
      color: black; /* Texto em preto */
    }
  }

  a:last-child {
    margin-right: 0;
  }

  .icon {
    margin-right: 8px;
  }

  @media (max-width: 768px) {
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    flex-direction: column;
    background-color: #343a40;
    position: absolute;
    top: 60px;
    right: 0;
    width: 100%;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  }
`;

const ConfigMenu = styled.div`
  position: relative;
  display: inline-block;
`;

const ConfigToggle = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 10px 15px;
  margin-right: 10px;
  border-radius: 20px;
  background-color: #5bc0de;
  cursor: pointer;
  color: white;

  &:hover {
    background-color: #46a2c6;
  }
`;

const ConfigDropdown = styled.div`
  position: absolute;
  top: 50px;
  right: 0;
  background-color: #2d2f33;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;
  z-index: 1000;

  a {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    color: white;
    text-decoration: none;
    background-color: #2d2f33;

    &:hover {
      background-color: #343a40;
    }

    .icon {
      margin-right: 8px;
    }
  }

  a:not(:last-child) {
    border-bottom: 1px solid #3a3f44;
  }

  @media (max-width: 768px) {
    position: static;
    width: 100%;
    box-shadow: none;
    a {
      width: 100%;
    }
  }
`;

const AppNavbar: React.FC = () => {
  const { isAuthenticated, logout, user } = useAuth();
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);

  const handleLogout = () => {
    sessionStorage.removeItem('reloadCount');
    logout();
  };

  const toggleMenu = () => setMenuOpen(!menuOpen);
  const toggleSettingsMenu = () => setSettingsOpen(!settingsOpen);

  return (
    <>
      <GlobalStyle />
      <Navbar>
        <Brand href="/">
          <img src="/logoheader.png" alt="Integrameli logo" />
        </Brand>
        <Hamburger onClick={toggleMenu} />
        <NavLinks isOpen={menuOpen}>
          <a
            href="/"
            style={{ backgroundColor: '#5bc0de' }}
            className="no-border-radius"
          >
            <FaHome size={18} className="icon" /> Consulta Preço
          </a>
          <a
            href="/Top20"
            style={{ backgroundColor: '#5cb85c' }}
            className="no-border-radius"
          >
            <FaCrown size={18} className="icon" /> Top 20 Mercado Livre
          </a>
          <a
            href="/Trends"
            style={{ backgroundColor: '#0275d8' }}
            className="no-border-radius"
          >
            <FaCrown size={18} className="icon" /> Trends Mercado Livre
          </a>
          <a
            href="/calculadora"
            style={{ backgroundColor: '#d9534f' }}
            className="no-border-radius"
          >
            <FaCalculator size={18} className="icon" /> Calculadora
          </a>
          {/* Botão VIP só aparece para usuários trial */}
          {user?.roleModulo === 'trial' && (
            <a href="https://pay.kiwify.com.br/QfT98xN" className="vip">
              <FaCrown size={18} className="icon" /> Assinar Plano VIP
            </a>
          )}
          <ConfigMenu>
            <ConfigToggle onClick={toggleSettingsMenu}>
              Configurações
            </ConfigToggle>
            {settingsOpen && (
              <ConfigDropdown>
                <a
                  href="https://integrameli.com.br/news"
                  className="info"
                  target="_blank"
                >
                  <FaNewspaper size={18} className="icon" /> Atualizações
                </a>
                {isAuthenticated && (
                  <>
                    <a
                      onClick={() => setShowPasswordModal(true)}
                      className="primary no-border-radius"
                    >
                      <FaKey size={18} className="icon" /> Trocar Senha
                    </a>
                  </>
                )}
              </ConfigDropdown>
            )}
          </ConfigMenu>
          <a onClick={handleLogout} className="danger">
            <FaSignOutAlt size={18} className="icon" /> Sair
          </a>
        </NavLinks>
      </Navbar>
      <ChangePasswordModal
        show={showPasswordModal}
        onHide={() => setShowPasswordModal(false)}
      />
    </>
  );
};

export default AppNavbar;
