import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  Button,
  Table,
  Spinner,
  FormSelect,
  InputGroup,
  FormControl,
  Modal,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { Compra, ApiResponseData } from '../../components/types';
import {
  FaFileExcel,
  FaFilePdf,
  FaPlus,
  FaEdit,
  FaTrash,
  FaSearch,
} from 'react-icons/fa';
import moment from 'moment';
import { formatCurrency } from '../../utils/formatCurrency';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import {
  PermissionButton,
  PermissionTable,
  PermissionWrapper,
} from '../../components/Permission';
import api from '../../services/api';
import { AxiosResponse } from 'axios';

const ComprasPage: React.FC = () => {
  const [compras, setCompras] = useState<Compra[]>([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(5);
  const [total, setTotal] = useState<number>(0);
  const [to, setTo] = useState<number>(0);
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [compraToDelete, setCompraToDelete] = useState<Compra | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    fetchCompras(currentPage);
  }, [currentPage, perPage]);

  const fetchCompras = async (page: number, searchQuery: string = '') => {
    setLoading(true);

    try {
      let url = `${
        import.meta.env.VITE_APP_API_URL
      }/compras?page=${page}&per_page=${perPage}`;
      if (searchQuery) {
        url += `&search=${searchQuery}`;
      }

      const response: AxiosResponse<ApiResponseData<Compra[]>> = await api.get(
        url
      );

      if (response.data.meta) {
        setCompras(response.data.data);
        setCurrentPage(response.data.meta.current_page);
        setTotalPages(response.data.meta.last_page);
        setPerPage(response.data.meta.per_page);
        setTotal(response.data.meta.total);
        setTo(response.data.meta.to);
      } else {
        toast.error(
          'Erro ao buscar compras: dados de paginação não encontrados.'
        );
      }
    } catch (error) {
      toast.error('Erro ao buscar compras.');
    } finally {
      console.clear();
      setLoading(false);
    }
  };

  const handleDeleteCompra = async (compraId: number) => {
    setLoading(true);

    try {
      await api.delete(
        `${import.meta.env.VITE_APP_API_URL}/compras/${compraId}`
      );

      toast.success('Compra excluída com sucesso!');
      fetchCompras(currentPage);
    } catch (error) {
      toast.error('Erro ao excluir compra.');
    } finally {
      setLoading(false);
      setShowDeleteModal(false);
    }
  };

  const handlePageChange = (newPage: number) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handlePerPageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleSearchKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      fetchCompras(1, search);
    }
  };

  const confirmDelete = (compra: Compra) => {
    setCompraToDelete(compra);
    setShowDeleteModal(true);
  };

  const exportToExcel = async () => {
    setExportLoading(true);
    try {
      const ws = XLSX.utils.json_to_sheet(compras);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Compras');
      XLSX.writeFile(wb, 'compras.xlsx');
      toast.success('Exportação para Excel realizada com sucesso!');
    } catch (error) {
      toast.error('Erro ao exportar para Excel.');
    } finally {
      setExportLoading(false);
    }
  };

  const exportToPDF = async () => {
    setExportLoading(true);
    try {
      const pdf = new jsPDF();
      const title = 'Relatório de Compras';
      const tableColumn = [
        'Produto',
        'Valor Total',
        'Nota Fiscal',
        'Data de Criação',
      ];
      const tableRows: any[] = [];

      compras.forEach((compra) => {
        if (compra.itens) {
          compra.itens.forEach((item) => {
            const compraData = [
              item.produto?.descricao || 'Produto não encontrado',
              formatCurrency(item.valor_total),
              compra.nota_fiscal,
              moment(compra.created_at).format('DD/MM/YYYY HH:mm:ss'),
            ];
            tableRows.push(compraData);
          });
        }
      });

      pdf.setFontSize(18);
      pdf.text(title, 13, 8);
      pdf.setFontSize(12);
      pdf.text('Data: ' + new Date().toLocaleDateString(), 165, 8);

      autoTable(pdf, {
        head: [tableColumn],
        body: tableRows,
        startY: 20,
        theme: 'grid',
        styles: { fontSize: 8, cellPadding: 2 },
        headStyles: { fillColor: [0, 0, 0], textColor: [255, 255, 255] },
      });

      pdf.save('compras.pdf');
      toast.success('Exportação para PDF realizada com sucesso!');
    } catch (error) {
      console.error('Erro ao exportar para PDF:', error);
      toast.error('Erro ao exportar para PDF.');
    } finally {
      setExportLoading(false);
    }
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 5;
    const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);
    let startPage = Math.max(1, currentPage - halfMaxPagesToShow);
    let endPage = Math.min(totalPages, currentPage + halfMaxPagesToShow);

    if (currentPage <= halfMaxPagesToShow) {
      endPage = Math.min(totalPages, maxPagesToShow);
    } else if (currentPage + halfMaxPagesToShow >= totalPages) {
      startPage = Math.max(1, totalPages - maxPagesToShow + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  return (
    <PermissionWrapper screenId={'5'} permission="access">
      <div className="container">
        <InputGroup className="mb-3">
          <FormControl
            placeholder="Pesquisar por produto ou nota fiscal"
            aria-label="Pesquisar"
            value={search}
            onChange={handleSearchChange}
            onKeyDown={handleSearchKeyPress}
          />
          <InputGroup.Text>
            <Button
              variant="outline-secondary"
              onClick={() => fetchCompras(1, search)}
            >
              <FaSearch />
            </Button>
          </InputGroup.Text>
        </InputGroup>
        <div className="d-flex flex-wrap">
          <PermissionButton
            permission="create"
            variant="success"
            onClick={() => navigate('/compras/novo')}
            className="mr-2 mb-2 no-border-radius"
          >
            <FaPlus /> Nova Compra
          </PermissionButton>
          <div className="d-flex">
            <PermissionButton
              permission="exportExcel"
              variant="info"
              onClick={exportToExcel}
              className="mr-2 mb-2 no-border-radius mx-2"
              disabled={exportLoading}
            >
              {exportLoading ? (
                <Spinner
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <>
                  <FaFileExcel /> Exportar para Excel
                </>
              )}
            </PermissionButton>
            <PermissionButton
              permission="exportPdf"
              variant="warning"
              onClick={exportToPDF}
              className="mb-2 no-border-radius"
              disabled={exportLoading}
            >
              {exportLoading ? (
                <Spinner
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <>
                  <FaFilePdf /> Exportar para PDF
                </>
              )}
            </PermissionButton>
          </div>
          <FormSelect
            value={perPage}
            onChange={handlePerPageChange}
            className="mb-2 mx-2 no-border-radius"
            style={{ width: '100px' }}
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={15}>15</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={150}>150</option>
            <option value={500}>500</option>
            <option value={1000}>1000</option>
            <option value={5000}>5000</option>
          </FormSelect>
        </div>

        {loading ? (
          <div className="text-center">
            <Spinner animation="border" role="status">
              <span className="sr-only"></span>
            </Spinner>
          </div>
        ) : (
          <>
            <PermissionTable permission="view">
              <div className="table-responsive">
                <Table hover className="table-striped" id="table-to-print">
                  <thead className="thead-dark">
                    <tr>
                      <th>Id</th>
                      <th>Produto</th>
                      <th>Valor Total</th>
                      <th>Nota Fiscal</th>
                      <th>Data Criação</th>
                      <th>Status</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {compras.map((compra) => (
                      <React.Fragment key={compra.id}>
                        {compra.itens && compra.itens.length > 0 ? (
                          compra.itens.map((item) => (
                            <tr key={item.id}>
                              <td>{compra.id}</td>
                              <td>
                                {item.produto?.descricao ||
                                  'Produto não encontrado'}
                              </td>
                              <td>{formatCurrency(item.valor_total)}</td>
                              <td>{compra.nota_fiscal}</td>
                              <td>
                                {moment(compra.created_at).format(
                                  'DD/MM/YYYY HH:mm:ss'
                                )}
                              </td>
                              <td
                                style={{
                                  color:
                                    compra.status === 'cancelado'
                                      ? 'red'
                                      : compra.status === 'confirmado'
                                      ? 'green'
                                      : compra.status === 'pendente'
                                      ? 'blue'
                                      : 'black',
                                }}
                              >
                                {compra.status}
                              </td>
                              <td className="d-flex justify-content-around">
                                <PermissionButton
                                  permission="edit"
                                  variant="primary"
                                  onClick={() =>
                                    navigate(`/compras/editar/${compra.id}`)
                                  }
                                >
                                  <FaEdit /> Editar
                                </PermissionButton>

                                <PermissionButton
                                  permission="details"
                                  variant="info"
                                  onClick={() =>
                                    navigate(`/compras/detalhes/${compra.id}`)
                                  }
                                >
                                  Ver Detalhes
                                </PermissionButton>

                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`tooltip-disabled`}>
                                      {compra.status === 'confirmado' ||
                                      compra.status === 'cancelado'
                                        ? 'Não é possível excluir uma compra confirmada ou cancelada'
                                        : ''}
                                    </Tooltip>
                                  }
                                >
                                  <div style={{ display: 'inline-block' }}>
                                    <PermissionButton
                                      permission="delete"
                                      variant="danger"
                                      onClick={() => confirmDelete(compra)}
                                      disabled={
                                        compra.status === 'confirmado' ||
                                        compra.status === 'cancelado'
                                      }
                                      style={{
                                        pointerEvents:
                                          compra.status === 'confirmado' ||
                                          compra.status === 'cancelado'
                                            ? 'none'
                                            : 'auto',
                                      }} // Isso evita o clique no botão desabilitado
                                    >
                                      <FaTrash /> Excluir
                                    </PermissionButton>
                                  </div>
                                </OverlayTrigger>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td>{compra.id}</td>
                            <td colSpan={2} className="text-center">
                              Nenhum item encontrado para esta compra.
                            </td>
                            <td>{compra.nota_fiscal}</td>
                            <td>
                              {moment(compra.created_at).format(
                                'DD/MM/YYYY HH:mm:ss'
                              )}
                            </td>
                            <td className="d-flex justify-content-around">
                              <Button
                                variant="primary"
                                size="sm"
                                onClick={() =>
                                  navigate(`/compras/editar/${compra.id}`)
                                }
                              >
                                <FaEdit /> Editar
                              </Button>
                              <Button
                                variant="info"
                                size="sm"
                                onClick={() =>
                                  navigate(`/compras/detalhes/${compra.id}`)
                                }
                              >
                                Ver Detalhes
                              </Button>
                              <Button
                                variant="danger"
                                size="sm"
                                onClick={() => confirmDelete(compra)}
                              >
                                <FaTrash /> Excluir
                              </Button>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))}
                  </tbody>
                </Table>
              </div>
              <br />
              <p className="text-muted">
                Mostrando {to} de {total} compras
              </p>
              <nav>
                <ul className="pagination justify-content-center">
                  <li
                    className={`page-item ${
                      currentPage === 1 ? 'disabled' : ''
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(currentPage - 1)}
                    >
                      Anterior
                    </button>
                  </li>
                  {getPageNumbers().map((pageNumber) => (
                    <li
                      key={pageNumber}
                      className={`page-item ${
                        currentPage === pageNumber ? 'active' : ''
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => handlePageChange(pageNumber)}
                      >
                        {pageNumber}
                      </button>
                    </li>
                  ))}
                  <li
                    className={`page-item ${
                      currentPage === totalPages ? 'disabled' : ''
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      Próxima
                    </button>
                  </li>
                </ul>
              </nav>
            </PermissionTable>
          </>
        )}

        <Modal
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirmar Exclusão</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Tem certeza de que deseja excluir a compra selecionada?
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowDeleteModal(false)}
            >
              Cancelar
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                if (compraToDelete) {
                  handleDeleteCompra(compraToDelete.id);
                }
              }}
            >
              Excluir
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </PermissionWrapper>
  );
};

export default ComprasPage;
