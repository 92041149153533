import React, { useEffect, useState } from 'react';
import AppNavbar from '../components/AppNavbar';
import TrialCheck from '../../../components/TrialCheck';
import Trends from '../../../components/Trends';

const TrendsModulo: React.FC = () => {
  const [isReloaded, setIsReloaded] = useState(false);

  useEffect(() => {
    let reloadCount = parseInt(
      sessionStorage.getItem('reloadCount') || '0',
      10
    );

    if (reloadCount < 1) {
      reloadCount += 1;
      sessionStorage.setItem('reloadCount', reloadCount.toString());
      window.location.reload();
    } else {
      setIsReloaded(true);
    }
  }, []);

  return (
    <>
      <AppNavbar />
      {isReloaded && (
        <div
          style={{
            padding: '20px',
            backgroundColor: '#f9fafb',
            minHeight: '100vh',
          }}
        >
          <TrialCheck>
            <div
              style={{
                margin: '0 auto',
                backgroundColor: '#fff',
                padding: '30px',
                borderRadius: '8px',
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
              }}
            >
              <div
                style={{
                  maxWidth: '700px',
                  // display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <h2
                  style={{
                    fontSize: '28px',
                    fontWeight: 'bold',
                    color: '#333',
                  }}
                ></h2>
              </div>
              <p
                style={{
                  fontSize: '16px',
                  color: '#555',
                  margin: '10px 0 20px',
                }}
              ></p>
              <Trends />
            </div>
          </TrialCheck>
        </div>
      )}
    </>
  );
};

export default TrendsModulo;
